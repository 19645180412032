import React, { useState } from 'react';
import { Grid } from '@mui/material';

export default function DefaultTextfield({
    value: initialValue = '',
    onChange = () => { },
    onTextClear = () => { },
    isDisabled = false,
    type = 'text',
    id = '',
    clearButtonId = '',
    name = '',
    label = '',
    placeholder = '',
    isRequired = false,
    onChangeGetEvent = () => {},
    clearButton = false,
    inputStyle = {},
    containerStyle = {}
}) {
    const [value, setValue] = useState(initialValue);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setValue(newValue);
        onChange(newValue);
        onChangeGetEvent(event);
    };

    const handleClear = () => {
        setValue('');
        onChange('');
        onTextClear();
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            fullwidth="true"
            sx={containerStyle}
        >
            {label !== '' && (
                <Grid item xs={2}>
                    <p style={{ marginTop: 8, fontWeight: "bold"}}>{label}:</p>
                </Grid>
            )}
            <Grid 
                item 
                xs={10}
            >
                <div style={{ 
                        position: 'relative' 
                    }}>
                    <input
                        type={type}
                        id={id}
                        name={name}
                        value={value}
                        onChange={handleChange}
                        className="form-control"
                        placeholder={placeholder}
                        disabled={isDisabled}
                        required={isRequired}
                        style={inputStyle}
                    />
                    {value && clearButton && (
                        <button
                            id={clearButtonId}
                            type="button"
                            onClick={handleClear}
                            style={{
                                position: 'absolute',
                                right: 10,
                                top: '50%',
                                transform: 'translateY(-50%)',
                                border: 'none',
                                background: 'none',
                                cursor: 'pointer'
                            }}
                        >
                            <span role="img" aria-label="Clear text" id="clearText">&#10006;</span>
                        </button>
                    )}
                </div>
            </Grid>
        </Grid>
    );
}
