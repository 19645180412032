import React, { useEffect, useState } from 'react';
import useClient from '../Client';

import { Grid } from '@mui/material';
import Header from './Header';
import { DataGrid } from '@mui/x-data-grid';

const QueryTable = ({
    columns = [],
    url,
    onRowClick = null,
    reInitializeOnChange,
    getRowId = (row) => row.id,
    rowHeight = 40,
    filter,
    search,
    getRowHeight = undefined, // set () => 'auto' for auto
    getResponse,
    isLoading,
    props
}) => {
    const client = useClient();

    const [initialized, setInitialized] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [rows, setRows] = useState([]);
    const [totalRows, setTotalRows] = useState(0);

    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);

    const [sortModel, setSortModel] = useState([]);
    const [filterModel, setFilterModel] = useState([]);
    const [searchValue, setSearchValue] = useState("");

    /** Time spent not typing before pinging for results, in milliseconds */
    const refreshTimer = 500;

    useEffect(() => {
        fetchFromUrl();
    }, []);

    useEffect(() => {
        ()=>isLoading(loading);
    }, [loading]);

    /* For websockets. Reinitialize table when event triggered */
    useEffect(() => {
        fetchFromUrl();
    }, Array.isArray(reInitializeOnChange) ? reInitializeOnChange:[reInitializeOnChange])

    useEffect(() => {
        if(initialized){
            fetchFromUrl();
        }
    }, [page, pageSize, sortModel]);

    useEffect(() => {
        let timerId = setTimeout(() => {
            if(initialized){
                fetchFromUrl();
            }
        }, refreshTimer);

        return () => {{
            clearTimeout(timerId);
        }}
    }, [searchValue, filterModel]);

    const fetchFromUrl = () => {
        reset();
        setLoading(true);

        client.get(url, {
            params: {
                querytable_pagesize: pageSize,
                querytable_page: page,
                querytable_sort: sortModel,
                querytable_filter: filterModel.filter((filter) => {
                    return filter.column !== null && filter.operator !== null;
                }),
                querytable_search: searchValue,
                querytable_columns: columns.filter((column) => {
                    return column.filter ?? true;
                }).map((column, i) => {
                    if(column.searchable){
                        return column.field;
                    }
                })
            }
        }).then((res) => {
            setRows(res.data.rows);
            setTotalRows(res.data.totalRows);
            getResponse(res);
        }).catch((err) => {
            setError(true);
        }).then(() => {
            setLoading(false);
            setInitialized(true);
        });
    }

    const reset = () => {
        setError(false);
        setRows([]);
        setTotalRows(0);
    }

    return (
        <Grid container>
            <Grid item sx={{
                width: '100%',
                mb: 0.5
            }}>
                <Header
                    columns={columns}
                    onSearchValueChange={(value) => setSearchValue(value)}
                    onFilterModeChange={(filters) => setFilterModel(filters)}
                    filter={filter}
                    search={search}
                />
            </Grid>

            <Grid item sx={{
                width: '100%'
            }}>
                <DataGrid
                    getRowHeight={getRowHeight}
                    columns={columns}
                    rows={rows}
                    getRowId={getRowId}
                    loading={loading}
                    autoHeight
                    disableColumnFilter
                    paginationMode="server"
                    sortingMode="server"
                    rowCount={totalRows}
                    pageSize={pageSize}
                    page={page}
                    rowsPerPageOptions={[10, 20, 50]}
                    rowHeight={rowHeight}
                    onPageSizeChange={(newPageSize) => {
                        setPage(0);
                        setPageSize(newPageSize);
                    }}
                    onPageChange={(newPage) => setPage(newPage)}
                    onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                    onRowClick={(params) => {
                        if(onRowClick){
                            onRowClick(params.row);
                        }
                    }}
                    sx={{
                        cursor: onRowClick !== null ? 'pointer' : 'auto'
                    }}
                    {...props}
                />
            </Grid>
        </Grid>
    )
}

export default QueryTable;