import React, { useRef, useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const ReCaptchaComponent = ({ resetReCaptcha, onVerified, onExpired, errorMessage }) => {
    const recaptchaRef = useRef();
    const [isVerified, setIsVerified] = useState(false);

    const handleVerify = (recaptchaValue) => {
        if (recaptchaValue) {
            setIsVerified(true);
            onVerified(recaptchaValue);
        } else {
            setIsVerified(false);
            onVerified(null);
        }
    };

    const handleExpire = () => {
        setIsVerified(false);
        onExpired();
    };

    useEffect(() => {
       
        if (errorMessage) {
            setIsVerified(false);
        }
    }, [errorMessage]);

    useEffect(() => {

        if (recaptchaRef.current && !isVerified) {
            recaptchaRef.current.reset(); 
        }
    }, [resetReCaptcha, isVerified]);

    return (
        <div>
            <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.MIX_NOCAPTCHA_SITEKEY}
                onChange={handleVerify}
                onExpired={handleExpire}
            />
            {!isVerified && errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </div>
    );
};

export default ReCaptchaComponent;
