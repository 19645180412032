import { Box, Paper, Grid } from "@mui/material";
import React from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import TextButton from "./Buttons/TextButton";

const PaperCardV2 = ({
    title = null,
    subTitle = null,
    name = null,
    customButtons = null,
    backBtnLabel = "",
    backBtnUrl = "",
    createBtnLabel = "",
    createBtnUrl = "",
    createBtnId = "",
    boxShadow = "",
    backgroundColor = "#ffffff",
    ...props
}) => {
    const PaperButtons = customButtons;

    return (
        <Box {...props}>
            <Paper
                sx={{
                    p: 2.5,
                    minHeight: "50%",
                    width: "100%",
                    mb: 0,
                    boxShadow: boxShadow,
                    backgroundColor: backgroundColor,
                }}
            >
                <Grid container columnSpacing={2} rowSpacing={subTitle ? 0 : 3}>
                    <Grid item xs={6}>
                        {title ? (
                            <div className="d-flex">
                                <h4 className="header-title">{title}</h4>
                                {name ? (
                                    <>
                                        <h4 style={{ paddingLeft: "10px" }}>
                                            -
                                        </h4>
                                        <h4
                                            className="header-title"
                                            style={{ paddingLeft: "10px" }}
                                        >
                                            {name}
                                        </h4>
                                    </>
                                ) : null}
                            </div>
                        ) : null}
                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-end">
                            {backBtnLabel ? (
                                <TextButton
                                    id="back_btn"
                                    variant={"contained"}
                                    sx={{
                                        backgroundColor: "#5b6268",
                                        color: "white",
                                        pl: 2,
                                        pr: 2,
                                        fontSize: "12px",
                                        marginRight: "4px",
                                    }}
                                    size="small"
                                    // onMouseEnter={(e) => {
                                    //     e.target.style.color = "#124d79";
                                    // }}
                                    // onMouseLeave={(e) => {
                                    //     e.target.style.color = "";
                                    // }}
                                    href={backBtnUrl}
                                >
                                    {backBtnLabel}
                                </TextButton>
                            ) : null}
                            {createBtnLabel ? (
                                <TextButton
                                    id={createBtnId}
                                    variant={"contained"}
                                    sx={{
                                        backgroundColor: "#1976d2",
                                        color: "white",
                                        pl: 2,
                                        pr: 2,
                                        fontSize: "12px",
                                        marginRight: "4px",
                                    }}
                                    size="small"
                                    // onMouseEnter={(e) => {
                                    //     e.target.style.color = "#124d79";
                                    // }}
                                    // onMouseLeave={(e) => {
                                    //     e.target.style.color = "";
                                    // }}
                                    href={createBtnUrl}
                                >
                                    <AddOutlinedIcon fontSize="small" />{" "}
                                    {createBtnLabel}
                                </TextButton>
                            ) : null}
                            {customButtons ? <PaperButtons /> : null}
                        </Box>
                    </Grid>
                    {subTitle ? (
                        <Grid item xs={6}>
                            <p
                                className="sub-header text-muted"
                                style={{ margin: 0 }}
                            >
                                {subTitle}
                            </p>
                        </Grid>
                    ) : null}
                </Grid>
                {props.children}
            </Paper>
        </Box>
    );
};

export default PaperCardV2;
