// src/Keypad.jsx

import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";

const Keypad = ({ onIdChange, isGoIncluded = false, label = '', isGoButtonClicked}) => {
    const button_array = !isGoIncluded ? [1, 2, 3, 4, 5, 6, 7, 8, 9, "DEL", 0] : [1, 2, 3, 4, 5, 6, 7, 8, 9, "DEL", 0, "GO"];
    const [inputValue, setInputValue] = useState("");
    const [goValue, setGoValue] = useState(0);

    useEffect(() => {
        onIdChange(inputValue);
    }, [inputValue, onIdChange]);

    const handleButtonClick = (value) => {
        setInputValue((prev) => (prev.length < 6 ? prev + value : prev));
    };

    const handleDelete = () => {
        setInputValue((prev) => prev.slice(0, -1));
    };

    const handleGo = () => {
        setGoValue(prevGoValue => prevGoValue + 1);
    };

    useEffect(() => {
        isGoButtonClicked(goValue);
    }, [goValue, isGoButtonClicked]);

    const handleChange = (event) => {
        const newValue = event.target.value;
        if (newValue.length <= 6) {
            setInputValue(newValue);
        }
    };

    const deleteStyles = {
        fontSize: "30px",
        padding: "5px 20px",
        borderRadius: "100px",
        width: "140px",
        border: "1px solid #34A1FF",
        backgroundColor: "#d43939 !important",
        color: "white !important", 
        margin: "3px",
        "&:hover": {
            backgroundColor: "#f0f0f0",
        },
    };

    const buttonStyles = {
        fontSize: "30px",
        padding: "5px 20px",
        borderRadius: "100px",
        width: "140px",
        border: "1px solid #34A1FF",
        backgroundColor: "white",
        margin: "3px",
        "&:hover": {
            backgroundColor: "#f0f0f0",
        },
    };

    const goStyles = {
        fontSize: "30px",
        padding: "5px 20px",
        borderRadius: "100px",
        width: "140px",
        border: "1px solid #34A1FF",
        backgroundColor: "#53cf74 !important",
        color: "white !important", 
        margin: "3px",
        "&:hover": {
            backgroundColor: "#f0f0f0",
        },
    };
    const inputStyles = {
        fontSize: '30px',
        width: '330px',
        fontWeight: 'lighter',
        mb: 2,
        textAlign: 'center', 
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" p={2}>
            <Typography variant="h6" gutterBottom>
                {label}
            </Typography>
            <TextField
                variant="outlined"
                value={inputValue}
                onChange={handleChange}
                inputProps={{
                    style: { textAlign: 'center'}
                }}
                InputProps={{
                    readOnly: true,
                    sx: inputStyles,
                    style: {
                        backgroundColor: 'transparent',
                    },
                }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        backgroundColor: 'transparent',
                    },
                }}
            />
            <Box
                display="grid"
                gridTemplateColumns="repeat(3, 1fr)"
                gap={1}
                mb={2}
            >
                {button_array.map((key) =>
                    key === "DEL" || key === "GO" ? (
                        <Button
                            key={key}
                            variant="outlined"
                            onClick={key === "DEL" ? handleDelete : handleGo}
                            sx={key === "DEL" ? deleteStyles : goStyles}
                        >
                            {key}
                        </Button>
                    ) : (
                        <Button
                            key={key}
                            variant="outlined"
                            onClick={() => handleButtonClick(key.toString())}
                            sx={buttonStyles}
                        >
                            {key}
                        </Button>
                    )
                )}
            </Box>
        </Box>
    );
};

export default Keypad;
