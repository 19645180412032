import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import DefaultDatePicker from "../CustomComponents/DefaultDatePicker";
import DefaultTextfield from "../CustomComponents/DefaultTextfield";
import DefaultSelect from "../CustomComponents/DefaultSelect";
import DateRangePicker from "react-bootstrap-daterangepicker";
import axios from "axios";
import { IconButton, Tooltip, Chip, Button, Box, Grid, Stack } from "@mui/material";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import TextButton from "../../components/Buttons/TextButton";
import MainDatePicker from "../Form/MainDatePicker";
import {MaterialReactTable, MRT_ToggleFiltersButton, MRT_ShowHideColumnsButton, MRT_ToggleDensePaddingButton} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DefaultButton from "../CustomComponents/DefaultButton";
import PromptDialog from "../CustomComponents/PromptDialog";
import DescriptionIcon from "@mui/icons-material/Description";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Client from "../../../js/components/Client";
import IndexQueryTableDateRangePicker from "./IndexQueryTableDateRangePicker";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import TextField  from '@mui/material/TextField';
import MenuItem  from '@mui/material/MenuItem';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { base64Images } from "./resources";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
// import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query'

const svgIcons = {
    down_arrow: `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15" zoomAndPan="magnify" viewBox="0 0 50 50" height="15" preserveAspectRatio="xMidYMid meet" version="1.0"><defs><clipPath id="b9270ae0ba"><path d="M 1.085938 0 L 29 0 L 29 17.023438 L 1.085938 17.023438 Z M 1.085938 0 " clip-rule="nonzero"/></clipPath><clipPath id="83cdee984f"><path d="M 1.085938 20.121094 L 29 20.121094 L 29 37 L 1.085938 37 Z M 1.085938 20.121094 " clip-rule="nonzero"/></clipPath></defs><g clip-path="url(#b9270ae0ba)"><path fill="#000000" d="M 15 0.0078125 L 1.085938 17.308594 L 8.648438 17.308594 L 8.648438 54.449219 L 21.351562 54.449219 L 21.351562 17.308594 L 28.910156 17.308594 Z M 15 0.0078125 " fill-opacity="1" fill-rule="nonzero"/></g><g clip-path="url(#83cdee984f)"><path fill="#d9d9d9" d="M 15 36.996094 L 1.085938 19.699219 L 8.648438 19.699219 L 8.648438 -17.441406 L 21.351562 -17.441406 L 21.351562 19.699219 L 28.910156 19.699219 Z M 15 36.996094 " fill-opacity="1" fill-rule="nonzero"/></g></svg>
    `,
    up_arrow: `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15" zoomAndPan="magnify" viewBox="0 0 50 50" height="15" preserveAspectRatio="xMidYMid meet" version="1.0"><defs><clipPath id="14d7676928"><path d="M 1.085938 0 L 29 0 L 29 17.023438 L 1.085938 17.023438 Z M 1.085938 0 " clip-rule="nonzero"/></clipPath><clipPath id="db54fc07de"><path d="M 1.085938 20.121094 L 29 20.121094 L 29 37 L 1.085938 37 Z M 1.085938 20.121094 " clip-rule="nonzero"/></clipPath></defs><g clip-path="url(#14d7676928)"><path fill="#d9d9d9" d="M 15 0.0078125 L 1.085938 17.308594 L 8.648438 17.308594 L 8.648438 54.449219 L 21.351562 54.449219 L 21.351562 17.308594 L 28.910156 17.308594 Z M 15 0.0078125 " fill-opacity="1" fill-rule="nonzero"/></g><g clip-path="url(#db54fc07de)"><path fill="#000000" d="M 15 36.996094 L 1.085938 19.699219 L 8.648438 19.699219 L 8.648438 -17.441406 L 21.351562 -17.441406 L 21.351562 19.699219 L 28.910156 19.699219 Z M 15 36.996094 " fill-opacity="1" fill-rule="nonzero"/></g></svg>
    `,
    blank_svg: `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15" zoomAndPan="magnify" viewBox="0 0 50 50" height="15" preserveAspectRatio="xMidYMid meet" version="1.0"><defs><clipPath id="ab1913b049"><path d="M 1.085938 0 L 29 0 L 29 17.023438 L 1.085938 17.023438 Z M 1.085938 0 " clip-rule="nonzero"/></clipPath><clipPath id="7caa753f49"><path d="M 1.085938 20.121094 L 29 20.121094 L 29 37 L 1.085938 37 Z M 1.085938 20.121094 " clip-rule="nonzero"/></clipPath></defs><g clip-path="url(#ab1913b049)"><path fill="#d9d9d9" d="M 15 0.0078125 L 1.085938 17.308594 L 8.648438 17.308594 L 8.648438 54.449219 L 21.351562 54.449219 L 21.351562 17.308594 L 28.910156 17.308594 Z M 15 0.0078125 " fill-opacity="1" fill-rule="nonzero"/></g><g clip-path="url(#7caa753f49)"><path fill="#d9d9d9" d="M 15 36.996094 L 1.085938 19.699219 L 8.648438 19.699219 L 8.648438 -17.441406 L 21.351562 -17.441406 L 21.351562 19.699219 L 28.910156 19.699219 Z M 15 36.996094 " fill-opacity="1" fill-rule="nonzero"/></g></svg>
    `
};

const XLSX = require("xlsx");
const queryClient = new QueryClient();
const client = Client();
const moment = require("moment");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const exportFormats = {
    pdf: 'PDF',
    excel: 'Excel'
};

const exportConfig = {
    styles: {
        bold: {
            bold: true
        },
        centerText: {
            alignment: 'center'
        },
        underlinedText: {
            decoration: 'underline'
        },
        italicText: {
            italics: false
        },
        tableNameText: {
            alignment: 'center',
            decoration: 'underline',
            bold: true,
            margin: 10
        },
        tableRowText: {
            fontSize: 9
        },
        tableHeaderText: {
            fontSize: 9,
            bold: true
        },
        tableStyle: {
            margin: [0, 0, 0, 0]
        },
        footerText: {
            fontSize: 9,
            alignment: 'right',
            margin: [0, 0, 10, 0]
        }
    }
};

function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);
    return debouncedValue;
}

export default function IndexQuerytable({
    endpoint = "",
    method = "get",
    matrix = false,
    showColumnOrdering = true,
    columns = [],
    tableName = "Index",
    dateFilterColumn = null,
    includeNullDates = true,
    exportFilename = null,
    customExport = false,
    exportable = false,
    hideColumns = [],
    searchFilter = '',
    paginationLabel = true,
    createUrl = "",
    createLabel = "Create",
    onFilterChange = () => {},
    renderAdditionalFilters = () => {},
    delayRowCount = true,
    filtersComponent = null,
    enableGlobalFilter = true,
    columnResizing = false,
    tablePaperStyle = {
        sx: {
            boxShadow: "none",
            paddingTop: tableName === "Small" ? "0px" : "40px",
        },
    },
    rowsPaginationOptions = [20, 50, 100],
    initialPageSize = 20,
    renderExportPdf = null,
    defaultExportFormat = 'excel',
    useDefaultColumn = true,
    rowHeight = 50,
    initialSortingStatus = [],
    disableSavingSort = false,
    disableSavingColumnArrangement = false,
    disableSavingColumnVisibility = false,
}) {
    function filterType(datatype, options = []) {
        switch (datatype) {
            case "select":
                return {
                    filterVariant: datatype,
                    Filter: ({ column, header, table }) => {
                        const renderedOptions = options.map((option, i) => {
                            return {
                                value: i,
                                label: option,
                            };
                        });
                        const [selectedItem, setSelectedItem] = useState(
                            renderedOptions[0]
                        );
                        return (
                            <DefaultSelect
                                id={column.id + "_filterField"}
                                options={renderedOptions}
                                selectedValue={selectedItem}
                                onSelectionChange={(value) => {
                                    setSelectedItem(value);
                                    header.column.setFilterValue(value.label);
                                }}
                            />
                        );
                    },
                };
            case "multi-select":
                return {
                    filterVariant: datatype,
                    Filter: ({ column, header, table }) => {
                        const renderedOptions = options.map((option, i) => {
                            return {
                                value: i,
                                label: option,
                            };
                        });
                        const [selectedItem, setSelectedItem] = useState(
                            renderedOptions[0]
                        );
                        return (
                            <DefaultSelect
                                id={column.id + "_filterField"}
                                options={renderedOptions}
                                showSearchBar={true}
                                selectedValue={selectedItem}
                                variant="multiselect"
                                onSelectionChange={(value) => {
                                    setSelectedItem(value);
                                    header.column.setFilterValue(
                                        value.map((item) => item.label)
                                    );
                                }}
                            />
                        );
                    },
                };
            case "range":
                return {
                    filterVariant: datatype,
                };
            case "date":
                return {
                    Filter: ({ column, header }) => {
                        return (
                            <IndexQueryTableDateRangePicker
                                id={column.id + "_filterField"}
                                variant="daterangepicker"
                                localeFormat="MM-DD-YYYY"
                                clearButton={true}
                                onChange={(from, to) => {
                                    header.column.setFilterValue({
                                        from: from,
                                        to: to,
                                    });
                                }}
                            />
                        );
                    },
                };
            case "datetime":
                return {
                    Filter: ({ column, header }) => {
                        return (
                            <IndexQueryTableDateRangePicker
                                id={column.accessorKey + "_filterField"}
                                // localeFormat="MM/dd/yyy - MM/dd/yyy"
                                variant="datetimerangepicker"
                                onChange={(from, to) => {
                                    header.column.setFilterValue({
                                        from: from,
                                        to: to,
                                    });
                                }}
                            />
                        );
                    },
                };
            case "text":
            default:
                return {
                    filterVariant: datatype,
                    Filter: ({ column, header, table }) => {
                        return (
                            <DefaultTextfield
                                id={column.id + "_filterField"}
                                clearButtonId={column.id + "_clearButton"}
                                clearButton={true}
                                containerStyle={{
                                    padding: 0,
                                    display: "block",
                                }}
                                onChange={(value) => {
                                    header.column.setFilterValue(value);
                                }}

                            />
                        );
                    },
                };
        }
    }

    const [cols, setCols] = useState(columns.map((col) => {
        return {
            ...col,
            ...(col.canFilter
                ? (col.Filter ? {Filter: col.Filter} : filterType(
                      col.dataType,
                      col.hasOwnProperty("filterOptions")
                          ? col.filterOptions
                          : null
                  ))
                : {
                    Filter: ({ header }) => <center></center>,
                }),
        };
    }));

    useEffect(() => {
        setCols(columns.map((col) => {
            return {
                ...col,
                ...(col.canFilter
                    ? (col.Filter ? {Filter: col.Filter} : filterType(
                          col.dataType,
                          col.hasOwnProperty("filterOptions")
                              ? col.filterOptions
                              : null
                      ))
                    : {
                        Filter: ({ header }) => <center></center>,
                    }),
            };
        }))
    }, [columns]);

    return (
        <QueryClientProvider client={queryClient}>
            <Table
                endpoint={endpoint}
                method={method}
                matrix={matrix}
                showColumnOrdering={showColumnOrdering}
                columns={cols}
                exportFilename={exportFilename}
                customExport={customExport}
                tableName={tableName}
                dateFilterColumn={dateFilterColumn}
                hideColumns={hideColumns}
                searchFilter={searchFilter}
                paginationLabel={paginationLabel}
                createUrl={createUrl}
                includeNullDates={includeNullDates}
                createLabel={createLabel}
                renderAdditionalFilters={renderAdditionalFilters}
                exportable={exportable}
                onFilterChange={onFilterChange}
                delayRowCount={delayRowCount}
                filtersComponent={filtersComponent}
                enableGlobalFilter={enableGlobalFilter}
                columnResizing={columnResizing}
                tablePaperStyle={tablePaperStyle}
                rowsPaginationOptions={rowsPaginationOptions}
                initialPageSize={initialPageSize}
                renderExportPdf={renderExportPdf}
                defaultExportFormat={defaultExportFormat}
                useDefaultColumn={useDefaultColumn}
                rowHeight={rowHeight}
                initialSortingStatus={initialSortingStatus}
                disableSavingSort={disableSavingSort}
                disableSavingColumnArrangement={disableSavingColumnArrangement}
                disableSavingColumnVisibility={disableSavingColumnVisibility}
            />
            {/* <ReactQueryDevtools
                initialIsOpen={false}
            /> */}
        </QueryClientProvider>
    );
}

function DaterangeFilter({ onDateRangeChange, id }) {
    const [dateRange, setDateRange] = useState({
        start: null,
        end: null,
    });

    useEffect(() => {
        onDateRangeChange(dateRange);
    }, [dateRange]);

    return (
        <Grid item xs={12} sm={12} md={12} sx={{ p: 2, display: "flex" }}>
            <Grid item xs={12} sm={6} md={2}>
                <MainDatePicker
                    size="small"
                    variant="outlined"
                    name="start_date"
                    label="From"
                    id={id + "_from"}
                    fullWidth
                    maxDate={dateRange.end}
                    value={dateRange.start}
                    onChange={(value) => {
                        setDateRange({
                            start: value,
                            end: dateRange.end,
                        });
                    }}
                    PopperProps={{
                        style: { zIndex: 1000000 },
                    }}
                    sx={{
                        ".MuiOutlinedInput-input": {
                            fontSize: "12px",
                            height: "25px",
                            p: "4px 9px",
                        },
                        "& .MuiInputLabel-formControl": {
                            top: "-7px",
                            fontSize: "12px",
                        },
                        "& .MuiInputLabel-shrink": {
                            top: "0px",
                            fontSize: "16px",
                        },
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={2} sx={{ ml: 2, mr: 2 }}>
                <MainDatePicker
                    size="small"
                    variant="outlined"
                    name="end_date"
                    label="To"
                    id={id + "_to"}
                    fullWidth
                    value={dateRange.end}
                    onChange={(value) => {
                        setDateRange({
                            start: dateRange.start,
                            end: value,
                        });
                    }}
                    PopperProps={{
                        style: { zIndex: 1000000 },
                    }}
                    minDate={dateRange.start}
                    sx={{
                        ".MuiOutlinedInput-input": {
                            fontSize: "12px",
                            height: "25px",
                            p: "4px 9px",
                        },
                        "& .MuiInputLabel-formControl": {
                            top: "-7px",
                            fontSize: "12px",
                        },
                        "& .MuiInputLabel-shrink": {
                            top: "0px",
                            fontSize: "16px",
                        },
                    }}
                />
            </Grid>
        </Grid>
    );
}

function Table({
    endpoint,
    method,
    matrix,
    showColumnOrdering,
    columns,
    tableName,
    dateFilterColumn,
    hideColumns,
    searchFilter,
    paginationLabel,
    renderAdditionalFilters,
    exportable,
    includeNullDates,
    exportFilename,
    customExport,
    createUrl,
    createLabel,
    delayRowCount,
    onFilterChange,
    filtersComponent,
    enableGlobalFilter,
    columnResizing,
    tablePaperStyle,
    rowsPaginationOptions,
    initialPageSize,
    renderExportPdf,
    defaultExportFormat,
    useDefaultColumn,
    rowHeight,
    initialSortingStatus,
    disableSavingSort,
    disableSavingColumnArrangement,
    disableSavingColumnVisibility
}) {
    const refreshOnWindowsFocusUntil = 900; // number of seconds
    const debouncingTimeInMs = 500;
    const [canFetch, setCanFetch] = useState(true);
    const [resetPage, setResetPage] = useState(false);
    const [showExportDialog, setShowExportDialog] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const [columnFilters, setColumnFilters] = useState([]);
    const [additionalFilters, setAdditionalFilters] = useState({});
    const [sorting, setSorting] = useState(initialSortingStatus);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [isFetchingRowCount, setIsFetchingRowCount] = useState(false);
    const [showDone, setShowDone] = useState(false);
    const [resetBtn, setResetBtn] = useState(false);
    const [selectedExportFormat, setSelectedExportFormat] = useState(defaultExportFormat ? {value: defaultExportFormat, label: exportFormats[defaultExportFormat]} : {value: 'excel', label: 'Excel'});
    const [columnVisibility, setColumnsVisibility] = useState(
        hideColumns.reduce((acc, col) => {
            acc[col] = false;
            return acc;
        }, {})
    );

    const [columnOrder, setColumnOrder] = useState(
        columns.map((c) => c.accessorKey)
    );

    const [columnVisibility_, setColumnsVisibility_] = useState(
        hideColumns.reduce((acc, col) => {
            acc[col] = false;
            return acc;
        }, {})
    );

    const [columnOrder_, setColumnOrder_] = useState(
        columns.map((c) => c.accessorKey)
    );

    useEffect(() => {
        client
            .post(
                "/cis/index_query_table/show",
                {
                    screen: endpoint,
                }
            )
            .then((data) => {
                if(!disableSavingColumnArrangement){
                    setColumnOrder(data.data.column_arrangement ?? columnOrder)
                }
                if(!disableSavingColumnVisibility){
                    setColumnsVisibility(data.data.hidden_columns ?? columnVisibility)
                }
                if(!disableSavingSort){
                    setSorting(data.data.sorting ?? sorting)
                }
                setTimeout(() => {
                    setShowDone(true)
                }, 800);
            });
    }, []);

    useEffect(() => {
        setColumnOrder_(columns.map((c) => c.accessorKey))
    }, [columns]);

    useEffect(() => {
        if (showDone) {
            client
                .post(
                    "/cis/index_query_table/store",
                    {
                        screen: endpoint,
                        column_arrangement: columnOrder,
                        hidden_columns: columnVisibility,
                        sorting: sorting,
                    }
                );
        }
    }, [sorting, columnVisibility, columnOrder]);

    const previousHideColumnsRef = useRef();

    useEffect(() => {
        if (JSON.stringify(previousHideColumnsRef.current) !== JSON.stringify(hideColumns)) {
            setColumnsVisibility(
                hideColumns.reduce((acc, col) => {
                    acc[col] = false;
                    return acc;
                }, {})
            );
            previousHideColumnsRef.current = hideColumns;
        }
    }, [hideColumns]);

    useEffect(() => {
        setGlobalFilter(searchFilter);
    }, [searchFilter]);

  
    const [dateRange, setDateRange] = useState({
        column: dateFilterColumn,
        start: null,
        end: null,
    });
    
    const [globalFilter, setGlobalFilter] = useState(searchFilter);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: initialPageSize,
    });
    const debouncedColumnFilter = useDebounce(
        columnFilters,
        debouncingTimeInMs
    );
    const debouncedGlobalSearch = useDebounce(globalFilter, debouncingTimeInMs);

    const promiseQuery = async () => {
        const requestData = {
            columns: columns,
            filters: columnFilters,
            pageSize: pagination.pageSize,
            pageIndex: resetPage ? 0 : pagination.pageIndex,
            sorting: sorting ?? [],
            globalFilter: globalFilter,
            dateRange: dateFilterColumn != null ? dateRange : null,
            additionalFilters: additionalFilters,
            includeNullDates: includeNullDates,
            delayRowCount: delayRowCount,
            columnVisibility: columnVisibility
        };
        const requestParams = method.toLowerCase() === "get" ? requestData : undefined;
        const res = await axios({
            method,
            url: endpoint,
            data: requestData,
            params: requestParams
        });
        return res.data;
    };

    const { data, isError, isFetching, isLoading, refetch } = useQuery({
        queryKey: [
            "indexTable",
            debouncedColumnFilter,
            debouncedGlobalSearch,
            pagination.pageIndex,
            pagination.pageSize,
            sorting,
            dateRange,
            additionalFilters
        ],
        queryFn: promiseQuery,
        refetchOnWindowFocus: canFetch,
        onSuccess: (data) => {
            setResetPage(false);
            countdown(refreshOnWindowsFocusUntil);
            setCanFetch(false);
            if (delayRowCount) {
                setIsFetchingRowCount(true);
                fetchRowCount(data.addRows);
                setTotalRowCount(totalRowCount ?? 1000);
            }
            else {
                setTotalRowCount(data.totalRows);
            }
        },
    });
    const updateAdditionalFilters = (newFilter) => {
        setAdditionalFilters((prevFilters) => ({
            ...prevFilters,
            ...newFilter,
        }));
    };
    const createBtnId = createLabel
        ? createLabel.replace(/\s/g, "_") + "_btn"
        : null;

    function countdown(seconds) {
        let remainingTime = seconds;

        const intervalId = setInterval(() => {
            if (remainingTime <= 0) {
                clearInterval(intervalId);
                setCanFetch(true);
            } else {
                remainingTime--;
            }
        }, 1000);
    }

    function isFunctionWithCode(property) {
        if (typeof property === "function") {
            const functionString = property.toString();
            return functionString.includes("{") && functionString.includes("}");
        }
        return false;
    }

    function formatDataForExport(data, columns) {
        const formattedForExport = data.map((row) => {
            const formattedRow = {};
            const reorderedColumns = columnOrder.map(key => columns.find(column => column.accessorKey === key));
            reorderedColumns.map((col) => {
                if (columnVisibility?.[col.accessorKey] ?? true) {
                    if (
                        Object.hasOwnProperty.call(col, "renderExport") &&
                        isFunctionWithCode(col.renderExport)
                    ) {
                        const returnedData = col.renderExport(
                            row[col.accessorKey],
                            row
                        );
                        if (typeof returnedData === "string") {
                            formattedRow[col.header] = returnedData;
                        } else {
                            throw new Error(
                                "Returned data from renderExport function is not a string!"
                            );
                        }
                    } else {
                        formattedRow[col.header] = row[col.accessorKey];
                    }
                }
            });
            return formattedRow;
        });
        return formattedForExport;
    }

    function removeHtmlTagsFromArray(array) {
        const regex = /<[^>]*>/g;

        for (var i = 0; i < array.length; i++) {
            for (const key in array[i]) {
                if (
                    array[i].hasOwnProperty(key) &&
                    typeof array[i][key] === "string"
                ) {
                    array[i][key] = array[i][key].replace(regex, "");
                }
            }
        }

        return array;
    }

    function exportIntoFormat(data, format){
        switch (format) {
            case exportFormats.excel:
                const worksheetPage = XLSX.utils.json_to_sheet(data);
                const workbookPage = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(
                    workbookPage,
                    worksheetPage,
                    tableName
                );
                if (customExport == 'mrtt'){
                    data.forEach(entry => {
                        const sheetName = entry.Name.length > 31 ? entry.Name.substring(0, 31) : entry.Name;
                        const worksheetPage = XLSX.utils.json_to_sheet(entry.Status);
                        XLSX.utils.book_append_sheet(workbookPage, worksheetPage, sheetName);
                    });
                } 
                if (customExport == 'mrttsummary'){
                    data.forEach(entry => {
                        const sheetName = entry["Medical Report"].length > 31 ? entry["Medical Report"].substring(0, 31) : entry["Medical Report"];
                        const worksheetPage = XLSX.utils.json_to_sheet(entry[""]);
                        XLSX.utils.book_append_sheet(workbookPage, worksheetPage, sheetName);
                    });
                }

                XLSX.writeFile(
                    workbookPage,
                    (exportFilename ?? tableName) + ".xlsx",
                    { compression: true }
                );
                setIsExporting(false);
                break;
            case exportFormats.pdf:
                (async () => {
                    try {
                        var docDefinition = {
                            pageMargins: [10, 40, 10, 20],
                            pageOrientation: 'landscape',
                            header: {
                                image: await base64Images('sh_banner'),
                                width: 150,
                                margin: [350, 10]
                            },
                            footer: function (currentPage, pageCount) {
                                return {
                                    text: currentPage.toString() + ' of ' + pageCount,
                                    style: 'footerText'
                                };
                            },
                            content: renderExportPdf ? renderExportPdf(data) : [{
                                    text: tableName,
                                    style: 'tableNameText'
                                },
                                {
                                    table: {
                                        style: 'tableStyle',
                                        headerRows: 1,
                                        widths: data.map(item => Object.keys(item).map(key => 'auto'))[0],
                                        body: [
                                            data.map(item => Object.keys(item).map(key => ({
                                                text: key,
                                                style: 'tableHeaderText'
                                            })))[0],
                                            ...(data.map(item => Object.values(item).map(key => ({
                                                text: key,
                                                style: 'tableRowText'
                                            }))))
                                        ]
                                    }
                                }
                            ],
                            styles: exportConfig.styles
                        };
                        pdfMake.createPdf(docDefinition).open();
                        setIsExporting(false);
                    } catch (error) {
                        console.error('Error:', error);
                    }
                })();
                break;
            }
    }

    function exportData(operation) {
        setShowExportDialog(false);
        switch (operation) {
            case "page":
                const formattedData = removeHtmlTagsFromArray(
                    formatDataForExport(data.result, columns)
                );
                exportIntoFormat(formattedData, selectedExportFormat.label)
                break;
            case "all":
                const requestData = {
                    columns: columns,
                    filters: columnFilters,
                    pageSize: pagination.pageSize,
                    pageIndex: pagination.pageIndex,
                    sorting: sorting ?? [],
                    globalFilter: globalFilter,
                    dateRange: dateFilterColumn != null ? dateRange : null,
                    additionalFilters: additionalFilters,
                    forExport: true,
                    columnVisibility: columnVisibility
                };

                const params = method.toLowerCase() === "get" ? requestData : undefined;

                client({
                    method: method,
                    url: endpoint,
                    data: method.toLowerCase() === "get" ? undefined : requestData,
                    params: params
                }).then((response) => {
                    const formattedData = removeHtmlTagsFromArray(
                        formatDataForExport(response.data.result, columns)
                    );
                    exportIntoFormat(formattedData, selectedExportFormat.label)
                })
                .catch(() => {
                    alert(
                        "Error exporting entire data! Please contact IT."
                    );
                    setIsExporting(false);
                });
            break;
        }
    }

    function fetchRowCount(addRow) {
        client({
            method: method,
            url: endpoint,
            data:
                method.toLowerCase() === "get"
                    ? undefined
                    : {
                          columns: columns,
                          filters: columnFilters,
                          pageSize: pagination.pageSize,
                          pageIndex: resetPage ? 0 : pagination.pageIndex,
                          sorting: sorting ?? [],
                          globalFilter: globalFilter,
                          dateRange:
                              dateFilterColumn != null ? dateRange : null,
                          additionalFilters: additionalFilters,
                          includeNullDates: includeNullDates,
                          endPoint: endpoint,
                          delayRowCount: false,
                          forRowCounting: true,
                          columnVisibility: columnVisibility
                      },
            params:
                method.toLowerCase() === "get"
                    ? {
                          columns: columns,
                          filters: columnFilters,
                          pageSize: pagination.pageSize,
                          pageIndex: resetPage ? 0 : pagination.pageIndex,
                          sorting: sorting ?? [],
                          globalFilter: globalFilter,
                          dateRange:
                              dateFilterColumn != null ? dateRange : null,
                          additionalFilters: additionalFilters,
                          includeNullDates: includeNullDates,
                          endPoint: endpoint,
                          delayRowCount: false,
                          forRowCounting: true,
                          columnVisibility: columnVisibility
                      }
                    : undefined,
        }).then((response) => {
            setTotalRowCount(response.data += addRow);
            setIsFetchingRowCount(false);
        });
    }

    useEffect(() => {
        setResetPage(true);
        setPagination({ pageIndex: 0, pageSize: pagination.pageSize }); //for resetting page when filtering
    }, [columnFilters, globalFilter, dateRange, additionalFilters]);

    useEffect(() => {
        onFilterChange(columnFilters, totalRowCount, dateRange);
    }, [
        columnFilters,
        globalFilter,
        dateRange,
        additionalFilters,
        totalRowCount,
    ]);

    function findAccessorKeyByHeader(header) {
        const foundObject = columns.find(
            (obj) => obj.header === header.replace(/[\r\n]/g, "")
        );
        if (foundObject) {
            return foundObject.accessorKey;
        }
        return null;
    }

    useEffect(() => {   // initialization of elements after rendering the page
        // appending svg and id beside the header names for sorting direction indicator
        document.querySelectorAll('.sorting_icons')?.forEach(element => {
            if(columns.some(column => column.accessorKey == element.id.replace("_sorting", ""))){
                element.parentNode.removeChild(element);
            }
        });
        const elementsClasses = document.getElementsByClassName('Mui-TableHeadCell-Content MuiBox-root');
        for (let i = 0; i < elementsClasses.length; i++) {
            const accessorKey = findAccessorKeyByHeader(elementsClasses[i].outerText);
            if(columns.some(column => column.accessorKey == accessorKey && column?.enableSorting != false)){
                const newElement = document.createElement('div');
                newElement.className = 'sorting_icons';
                newElement.innerHTML = (sorting[0]?.id != accessorKey ? svgIcons.blank_svg : (sorting[0].desc ? svgIcons.up_arrow : svgIcons.down_arrow));
                newElement.style.marginLeft = '5px';
                newElement.id = accessorKey + '_sorting';
                elementsClasses[i].appendChild(newElement);
                elementsClasses[i].setAttribute('title', 'Sort by ascending');
                elementsClasses[i].id = findAccessorKeyByHeader(elementsClasses[i].outerText) + '_header';
            }
        }
    }, [columnVisibility, columns]);

    const previousAccessorKeys = useRef(columns.map(c => c.accessorKey));

    useEffect(() => {
        const currentAccessorKeys = columns.map(c => c.accessorKey);
        const hasAccessorKeyChanged = currentAccessorKeys.some((key, index) => key !== previousAccessorKeys.current[index]);

        if (hasAccessorKeyChanged) {
            setColumnFilters([])
            setColumnOrder(columns.map((c) => c.accessorKey))
        }

        previousAccessorKeys.current = currentAccessorKeys;
    }, [columns]);

    useEffect(() => {
        if(sorting.length !== 0){
            document.querySelectorAll('.sorting_icons')?.forEach(element => {
                if(columns.some(column => column.accessorKey == element.id.replace("_sorting", ""))){
                    element.parentNode.removeChild(element);
                }
            });
            const elementsClasses = document.getElementsByClassName('Mui-TableHeadCell-Content MuiBox-root');
            for (let i = 0; i < elementsClasses.length; i++) {
                const accessorKey = findAccessorKeyByHeader(elementsClasses[i].outerText);
                if(columns.some(column => column.accessorKey == accessorKey && column?.enableSorting != false)){
                    const newElement = document.createElement('div');
                    newElement.className = 'sorting_icons';
                    newElement.innerHTML = (sorting[0]?.id != accessorKey ? svgIcons.blank_svg : (sorting[0].desc ? svgIcons.up_arrow : svgIcons.down_arrow));
                    newElement.style.marginLeft = '5px';
                    newElement.id = accessorKey + '_sorting';
                    elementsClasses[i].appendChild(newElement);
                    elementsClasses[i].setAttribute('title', 'Sort by ascending');
                    elementsClasses[i].id = findAccessorKeyByHeader(elementsClasses[i].outerText) + '_header';
                }
            }
        }
    }, [sorting, isFetching])

    useEffect(() => {
        onFilterChange(columnFilters, totalRowCount, dateRange);
    }, [
        columnFilters,
        globalFilter,
        dateRange,
        additionalFilters,
        totalRowCount,
    ]);

    return (
        <>
            <Dialog
				open={showExportDialog}
				fullWidth
				onClose={() => {
                    setShowExportDialog(false);
                    setIsExporting(false);
                }}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				PaperProps={{
					sx: {
						overflowY: 'visible'
					}
				}}
				sx={{
					zIndex: '99999 !important',
				}}
			>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={0}
                    sx={{
                        paddingRight: '40px'
                    }}
                >
                    <DialogTitle
                        id="alert-dialog-title"
                        sx={{
                            fontSize: 18,
                            fontWeight: 'bold'
                        }}
                    >
                        {"Export table data"}
                    </DialogTitle>
                    <DefaultSelect
                        id="export_index_table_filetype"
                        selectedValue={selectedExportFormat}
                        label="Format"
                        controlStyles={{
                            marginTop: '-10px'
                        }}
                        defaultSelectedItemIndex={1}
                        options={Object.entries(exportFormats).map(([key, value]) => ({
                            value: key,
                            label: value
                        }))}
                        onSelectionChange={(value) => {
                            setSelectedExportFormat(value);
                        }}
                    >
                    </DefaultSelect>
                </Stack>
				<Box
					sx={{
						marginX: 3,
						marginBottom: 2
					}}>
                    <Box
                        sx={{
                            padding: 2,
                        }}
                    >
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="stretch"
                            spacing={2}
                        >
                            <Grid
                                sx={{
                                    marginBottom: 1,
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    startIcon={<DescriptionIcon />}
                                    sx={{
                                        justifyContent: "flex-start",
                                        textAlign: "left",
                                    }}
                                    onClick={() => {
                                        exportData("page");
                                    }}
                                >
                                    Export current page only
                                </Button>
                            </Grid>
                            <Grid
                                sx={{
                                    marginBottom: 1,
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    startIcon={<FileCopyIcon />}
                                    sx={{
                                        justifyContent: "flex-start",
                                        textAlign: "left",
                                    }}
                                    onClick={() => {
                                        exportData("all");
                                    }}
                                >
                                    Export entire data
                                </Button>
                            </Grid>
                        </Grid>
                        <Box
                            sx={{
                                paddingLeft: 56,
                            }}
                        >
                            <DefaultButton
                                variant="contained"
                                label="Close"
                                onClick={() => {
                                    setShowExportDialog(false);
                                    setIsExporting(false);
                                }}
                            />
                        </Box>
                    </Box>
				</Box>
			</Dialog>
            {typeof filtersComponent === 'function' ? filtersComponent(updateAdditionalFilters) : filtersComponent}
            <MaterialReactTable
                columns={useMemo(() => columns)}
                data={data ? data.result : []}
                rowCount={totalRowCount}
                isLoading={isFetching}
                onPaginationChange={setPagination}
                onColumnFiltersChange={setColumnFilters}
                onColumnVisibilityChange={setColumnsVisibility}
                onColumnOrderChange={setColumnOrder}
                enableColumnDragging={showColumnOrdering}
                enableColumnOrdering={showColumnOrdering}
                enableSorting={false}
                muiToolbarAlertBannerProps={
                    isError
                        ? {
                              color: "error",
                              children:
                                  tableName +
                                  " table is not functioning correctly. Please try refreshing the webpage. If the issue persists, contact IT.",
                          }
                        : undefined
                }
                onGlobalFilterChange={setGlobalFilter}
                manualFiltering
                manualPagination
                isMultiSortEvent={() => (matrix ? true : false)}
                enableFullScreenToggle={false}
                // enableColumnActions={false}
                enableColumnFilters={true}
                enableDensityToggle={false}
                enableHiding={matrix ? false : true}
                enableGlobalFilter={enableGlobalFilter}
                renderTopToolbarCustomActions={() => {
                    const [marginLeft, setMarginLeft] = useState("88%");
                    useEffect(() => {
                        const updateMarginLeft = () => {
                            if (window.innerWidth >= 1832) {
                                setMarginLeft("91%");
                            } else if (window.innerWidth >= 1732) {
                                setMarginLeft("90%");
                            } else if (window.innerWidth >= 1632) {
                                setMarginLeft("89%");
                            } else if (window.innerWidth >= 1532) {
                                setMarginLeft("88%");
                            } else if (window.innerWidth >= 1432) {
                                setMarginLeft("87%");
                            } else if (window.innerWidth >= 1332) {
                                setMarginLeft("85%");
                            } else if (window.innerWidth >= 1232) {
                                setMarginLeft("84%");
                            } else if (window.innerWidth >= 1132) {
                                setMarginLeft("81%");
                            } else if (window.innerWidth >= 1032) {
                                setMarginLeft("79%");
                            } else if (window.innerWidth >= 832) {
                                setMarginLeft("71%");
                            } else if (window.innerWidth >= 766) {
                                setMarginLeft("69%");
                            } else if (window.innerWidth <= 766) {
                                setMarginLeft("79%");
                            }
                        };
                        window.addEventListener("resize", updateMarginLeft);
                        updateMarginLeft();

                        return () => {
                            window.removeEventListener(
                                "resize",
                                updateMarginLeft
                            );
                        };
                    }, []);
                    return (
                        <>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                sx={{
                                    p: 0,
                                    display: "flex",
                                    marginTop: matrix ? "-75px" : "-65px",
                                }}
                            >
                                <Grid>
                                    {dateFilterColumn != null ? (
                                        <DaterangeFilter
                                            column={dateFilterColumn}
                                            id={tableName + '_daterangefilter_id'}
                                            onDateRangeChange={(value) => {
                                                // if (
                                                //     value.start != null ||
                                                //     value.end != null
                                                // ) {
                                                //     setDateRange({
                                                //         column: dateFilterColumn,
                                                //         start: value.start,
                                                //         end: value.end,
                                                //     });
                                                // }
                                                setDateRange({
                                                    column: dateFilterColumn,
                                                    start: value.start,
                                                    end: value.end,
                                                });
                                            }}
                                        />
                                    ) : null}
                                    {exportable ? (
                                        <Box
                                            sx={{
                                                marginX: "12.5px",
                                                marginTop:
                                                    dateFilterColumn != null
                                                        ? 0
                                                        : "58px",
                                            }}
                                        >
                                            <DefaultButton
                                                isLoading={isExporting}
                                                disabled={isFetching}
                                                label="Export Data"
                                                variant="outlined"
                                                startIcon={<FileDownloadIcon />}
                                                onClick={() => {
                                                    setShowExportDialog(true);
                                                    setIsExporting(true);
                                                }}
                                            />
                                        </Box>
                                    ) : null}
                                </Grid>
                                <br />
                                <Grid>
                                    {renderAdditionalFilters(
                                        updateAdditionalFilters
                                    )}
                                </Grid>

                                {createLabel && (
                                    <Grid
                                        sx={{
                                            paddingTop: "17px",
                                            right: "20px",
                                            position: "absolute",
                                        }}
                                    >
                                        <TextButton
                                            id={createBtnId}
                                            href={createUrl}
                                            sx={{
                                                backgroundColor: "#1976d2",
                                                color: "white",
                                                pl: 2,
                                                pr: 2,
                                                fontSize: "12px",
                                            }}
                                            size="small"
                                        >
                                            <AddOutlinedIcon fontSize="small" />
                                            <span
                                                style={{
                                                    whiteSpace: "nowrap",
                                                }}
                                            >
                                                {createLabel}
                                            </span>
                                        </TextButton>
                                    </Grid>
                                )}
                            </Grid>
                        </>
                    );
                }}
                renderToolbarInternalActions={({ table }) => (
                    <Box display="flex" alignItems="center">
                        <MRT_ToggleFiltersButton table={table} />
                        <MRT_ShowHideColumnsButton table={table} />
                        <Tooltip title="Reset default table">
                            <IconButton
                                disabled={resetBtn}
                                onClick={() => {
                                    setColumnOrder(columnOrder_)
                                    setColumnsVisibility(columnVisibility_)
                                    document.querySelectorAll("[id$='_sorting']").forEach(element => element.innerHTML = svgIcons.blank_svg);
                                    setSorting([])
                                    setResetBtn(true)
                                    client
                                        .post(
                                            "/cis/index_query_table/store",
                                            {
                                                screen: endpoint,
                                                column_arrangement: columnOrder_,
                                                hidden_columns: columnVisibility_,
                                                sorting: [],
                                            }
                                        )
                                        .then(() => {
                                            setResetBtn(false)
                                        });
                                }}
                            >
                                <RestartAltIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                muiTopToolbarProps={{
                    sx: {
                        overflow: "visible",
                    },
                }}
                enableStickyHeader={true}
                state={{
                    showAlertBanner: isError,
                    showProgressBars: isFetching,
                    columnFilters,
                    globalFilter,
                    isLoading: resetBtn ? resetBtn : isLoading,
                    pagination,
                    sorting,
                    columnVisibility,
                    columnOrder
                }}
                initialState={{
                    density: "compact",
                    columnVisibility: columnVisibility,
                    showGlobalFilter: true,
                    columnPinning: { left: ['matrix'] },
                    sorting: sorting,
                    columnVisibility: columnVisibility,
                    columnOrder: columnOrder
                }}
                muiSearchTextFieldProps={{
                    variant: "standard",
                    inputProps: {
                        sx: {
                            backgroundColor: "#FFFFFF !important",
                        },
                        id: "global_filter_field",
                    },
                }}
                muiTablePaperProps={tablePaperStyle}
                muiTableContainerProps={{
                    sx: {
                        maxHeight: "unset",
                    },
                }}
                muiTableHeadCellProps={{
                    onClick: (event) => {
                        let columnToSort;
                        if(typeof event.target.className == 'object'){
                            columnToSort = event.target.closest('div').id.replace("_sorting", "");
                        } else if(event.target.className.includes('TableHeadCell-Content')) {
                            columnToSort = findAccessorKeyByHeader(event.target.outerText);
                        }
                        if(columnToSort != undefined){
                            if(sorting.length != 0){
                                if(sorting[0].id != columnToSort){
                                    document.getElementById(columnToSort + '_sorting').innerHTML = svgIcons.down_arrow;
                                    setSorting([{
                                        id: columnToSort,
                                        desc: false
                                    }]);
                                } else if (sorting[0].id == columnToSort){
                                    if(!sorting[0].desc){
                                        document.getElementById(columnToSort + '_sorting').innerHTML = svgIcons.up_arrow;
                                        setSorting([{
                                            id: columnToSort,
                                            desc: true
                                        }]);
                                    } else {
                                        document.getElementById(columnToSort + '_sorting').innerHTML = svgIcons.blank_svg;
                                        setSorting([]);
                                    }
                                }
                            } else {
                                document.getElementById(columnToSort + '_sorting').innerHTML = svgIcons.down_arrow;
                                setSorting([{
                                    id: columnToSort,
                                    desc: false
                                }]);
                            }
                        }
                    },
                    onMouseEnter: (event) => {
                        try {
                            const columnForTooltip = findAccessorKeyByHeader(event.target.outerText);
                            const elementColumn = document.getElementById(columnForTooltip  + '_header');
                            elementColumn.style.cursor = 'pointer';
                            if(sorting.length != 0){
                                if(sorting[0].id != columnForTooltip){
                                    elementColumn.setAttribute('title', 'Sort by ascending order');
                                } else if (sorting[0].id == columnForTooltip){
                                    if(!sorting[0].desc){
                                        elementColumn.setAttribute('title', 'Sort by descending order');
                                    } else {
                                        elementColumn.setAttribute('title', 'Remove sorting');
                                    }
                                }
                            } else {
                                elementColumn.setAttribute('title', 'Sort by ascending');
                            }
                        } catch (error) {

                        }
                    },
                    sx: {
                        borderBottom: "2px solid black",
                        fontSize: "12px",
                        textAlign: "left",
                        verticalAlign: "middle",
                        'button:has(svg[data-testid="FilterAltIcon"])': {
                            display: "none",
                            pointerEvents: "none",
                        },
                        'button:has(svg[data-testid="FilterAltIcon"])': {
                            display: "none",
                            pointerEvents: "none",
                        },
                        'button:has(svg[data-testid="MoreVertIcon"])': {
                            display: "none",
                            pointerEvents: "none",
                        },
                        'span:has(svg[data-testid="ArrowDownwardIcon"])': {
                            display: matrix ? "block" : "none",
                            pointerEvents: matrix ? "auto" : "none",
                            marginRight: '3px'
                        },
                        ".Mui-TableHeadCell-Content-Labels": {
                            pointerEvents: matrix ? "auto" : "none",
                        },
                        ".MuiBadge-anchorOriginTopRightCircular": {
                            top: matrix ? "5px !important" : '',
                        },
                        ".MuiBadge-anchorOriginTopRightCircular": {
                            top: matrix ? "5px !important" : '',
                        },
                        // ".Mui-TableHeadCell-Content-Actions": {
                        //     display: 'none',
                        // }
                    },
                }}
                // muiTableHeadCellColumnActionsButtonProps={{
                //     sx: {
                //         display: 'none'
                //     }
                // }}
                muiTableBodyRowProps={(row) => {
                    return {
                        sx: {
                            height: rowHeight,
                        },
                        id: `row-id-${row.row.id}`,
                    };
                }}
                enableColumnResizing={columnResizing}
                muiTableBodyCellProps={(cell) => {
                    return {
                        sx: {
                            fontSize: "12px",
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            p: 0,
                            paddingLeft: 1,
                            alignContent: "left",
                            alignItems: "left",
                            verticalAlign: "middle",
                            textAlign:  cell.column.id === 'matrix' ? 'left' : (matrix ? "right" : "left"),
                        },
                        id: `${cell.column.id}`,
                    };
                }}
                defaultColumn={useDefaultColumn ? {
                    minSize: 0,
                    maxSize: 9001,
                    size: 30,
                } : {}}
                muiTablePaginationProps={{
                    showFirstButton: false,
                    showLastButton: false,
                    rowsPerPageOptions: rowsPaginationOptions,
                    labelDisplayedRows: ({ from, to, count }) => {
                        return isFetchingRowCount || !paginationLabel
                            ? "..."
                            : `${from}–${to} of ${
                                  count !== -1 ? count : `more than ${to}`
                              }`;
                    },
                    sx: {
                        fontSize: "12px",
                        textAlign: "left",
                        "& .MuiTablePagination-selectLabel": {
                            fontSize: "12px",
                            margin: 0,
                            display: "flex",
                            alignItems: "left",
                        },
                        "& .MuiTablePagination-displayedRows": {
                            fontSize: "12px",
                            margin: 0,
                            display: "flex",
                            alignItems: "left",
                        },
                        "& .MuiPopover-paper": {
                            backgroundColor: "red",
                            "& .MuiMenu-list": {
                                "& .MuiTablePagination-menuItem": {
                                    fontSize: "12px",
                                    color: "red",
                                },
                            },
                        },
                    },
                }}
            />
        </>
    );
}
