import React, { useEffect, useState } from 'react';
import { TableCell, Typography, TableSortLabel } from '@mui/material'

const Column = ({
    column,
    handleSortRequest,
    orderDirection,
    index
}) => {
    return (
        <React.Fragment key={index}>
            {column.sortable ?
                <TableCell onClick={()=>handleSortRequest(column.field, column.isNumeric)} sx={{
                        minWidth: column.width ?? 'auto'
                    }} key={index} align={column.align ?? "left"}>
                    <TableSortLabel active={false} direction={orderDirection}>
                        <Typography sx={{ fontWeight: 'bold' }}>{column.headerName}</Typography>
                    </TableSortLabel>
                </TableCell>
            :
                <TableCell sx={{
                    minWidth: column.width ?? 'auto'
                }} key={index} align={column.align ?? "left"}>
                    <Typography sx={{ fontWeight: 'bold' }}>{column.headerName}</Typography>
                </TableCell>
            }
        </React.Fragment>
    )
}

export default Column;
