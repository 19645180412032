import React, { useEffect, useState } from 'react';
import { Select, FormControl, InputLabel, MenuItem, FormHelperText, useTheme } from '@mui/material';
import useForm from './Form';
import Loading from '../Loading';
const MainSelect = ({
    options = [],
    value = null,
    label = '',
    name = '',
    onChange = () => {},
    renderOption = null,
    loading = false,
    inputVariant = 'filled',
    ...props
}) => {
    const formik = useForm();
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState(value === -1 ? null : value === null ? null : value)


    const handleDefaultValue = () => {
        let selectedOption = [];
        if ( formik.values[name] !== null && formik.values[name] !== '' &&  options.length!==0 && name != ''  ) {
            setIsLoading(true);
            if ( options[0] && options[0].hasOwnProperty('id') ) {
                const index = options.findIndex(object => {
                    return object.id === formik.values[name];
                });
                selectedOption = index ?? [];
            }
            else { selectedOption = options.indexOf(formik.values[name]) ?? []; }
            setSelected(selectedOption);
        }

        setIsLoading(false);
    }

    const handleChange = (e) => {
        let value = e.target.value;
        setSelected(value);
        onChange(value);

        if(formik){
            formik.setFieldValue(name, options[value].hasOwnProperty('id') ? options[value].id : renderOption ? renderOption(options[value]) : options[value]);
        }
    }
    useEffect(() => {
        if(selected !== null){
            onChange(options[selected]);
        }
    }, [selected]);

    useEffect(() => {
        if(formik){
            handleDefaultValue();
        }
    }, []);
    return (
        <FormControl 
            variant={inputVariant}
            error={formik?.touched[name] && Boolean(formik?.errors[name])}
            {...props}
        >
            <InputLabel sx={{'& .MuiFormLabel-asterisk': { color: theme.palette.error.main }, ...props.sx}}>{loading || isLoading ? <Loading size={20}/> : label}</InputLabel>

            <Select
                disabled={loading || isLoading ? true : undefined}
                value={selected !== null ? selected : ''}
                onChange={handleChange}
                label={label}
                id={name}
                name={name}
                {...props}
            >
                {options.map((option, i) => (
                    <MenuItem key={i} value={i}>
                        {renderOption ? 
                            renderOption(option)
                        : option.name ?
                            option.name
                        : option}
                    </MenuItem>
                ))}
                
            </Select>

            <FormHelperText>
                {formik?.touched[name] && formik?.errors[name]}
            </FormHelperText>
        </FormControl>
    )
}

export default MainSelect;