import React, { useEffect, useState } from 'react';
import { TableCell, TableRow, IconButton , ListItemText, Collapse} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

const Row = ({
    columns,
    row,
    rowIndex,
    noDataLabel,
    proop
}) => {

    const [open, setOpen] = useState(false);

    var i = columns.findIndex(object => {
        return object.collapsible == true;
    });

    return (
        <React.Fragment>
            <TableRow key={rowIndex}>
                {columns.map((column, colIndex) => (
                    <TableCell key={colIndex} align={column.align ?? "left"}>
                        {column.collapsible ?
                            <IconButton  onClick={() => setOpen(!open)}>
                                <ListItemText />
                                {open ? <ExpandLess /> : <ExpandMore />}
                            </IconButton >
                        :
                        column.renderCell ?
                            column.renderCell(row, rowIndex)
                        :
                            _.get(row, column.field) ? _.get(row, column.field) : noDataLabel
                        }
                    </TableCell>
                ))}

            </TableRow>

            <TableRow>
                <TableCell colSpan={columns.length} style={{ padding: 0 }}>
                    <Collapse in={open}>
                        {
                            i == -1 ? null : columns[i].component(row, rowIndex)
                        }
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

export default Row;
