import React, { useState, useEffect } from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Typography, TablePagination } from '@mui/material';
import Row from './Row';
import Column from './Column';
import Loading from '../Loading';
import Center from '../Center';

const CollapsibleTable = ({
    columns = [],
    rows = [],
    small,
    noDataLabel = '',
    loading = false,
    noRowsLabel = 'No records to display',
    defaultRowsPerPage = 10, 
    ...props
}) => {
    const [page, setPage] = useState(0);
    const [rowData, setRowData] = useState([]);
    const [orderDirection, setOrderDirection] = useState("asc");
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

    useEffect(() => {
        setRowData(rows);
    }, [rows]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); 
    };

    const sortArray = (arr, orderBy, field, isNumeric) => {
        switch (orderBy) {
            case "asc":
            default:
                if (isNumeric) {
                    return arr.sort((a, b) => a[field] - b[field]);
                } else {
                    return arr.sort((a, b) => (a[field] > b[field] ? 1 : b[field] > a[field] ? -1 : 0));
                }
            case "desc":
                if (isNumeric) {
                    return arr.sort((a, b) => b[field] - a[field]);
                } else {
                    return arr.sort((a, b) => (a[field] < b[field] ? 1 : b[field] < a[field] ? -1 : 0));
                }
        }
    };

    const handleSortRequest = (field, isNumeric) => {
        setRowData(sortArray([...rows], orderDirection, field, isNumeric));
        setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    };

    const displayedRows = rowsPerPage > 0 ? rowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rowData;

    return (
        <React.Fragment>
            <TableContainer>
                <Table size={small ? 'small' : 'medium'} {...props}>
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => (
                                <Column
                                    key={index}
                                    column={column}
                                    orderDirection={orderDirection}
                                    handleSortRequest={(field, isNumeric) => handleSortRequest(field, isNumeric)}
                                />
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={columns.length}>
                                    <Center>
                                        <Loading />
                                    </Center>
                                </TableCell>
                            </TableRow>
                        ) : displayedRows.length <= 0 ? (
                            <TableRow>
                                <TableCell colSpan={columns.length}>
                                    <Center>
                                        <Typography>{noRowsLabel}</Typography>
                                    </Center>
                                </TableCell>
                            </TableRow>
                        ) : (
                            displayedRows.map((row, rowIndex) => (
                                <Row key={rowIndex} columns={columns} row={row} rowIndex={rowIndex} noDataLabel={noDataLabel} />
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {rowsPerPage > 0 && (
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{ 
                        "& .MuiTablePagination-selectLabel": {
                            fontSize: "12px",
                            margin: 0,
                            display: "flex",
                            alignItems: "left",
                        },
                        "& .MuiTablePagination-displayedRows": {
                            fontSize: "12px",
                            margin: 0,
                            display: "flex",
                            alignItems: "left",
                        },
                        "& .MuiPopover-paper": {
                            backgroundColor: "red",
                            "& .MuiMenu-list": {
                                "& .MuiTablePagination-menuItem": {
                                    fontSize: "12px",
                                    color: "red",
                                },
                            },
                        },
                    }}
                />
            )}
        </React.Fragment>
    );
};

export default CollapsibleTable;
