import React, { useMemo, useState, useEffect, Fragment } from "react";
import {
    Paper,
    Grid,
    Select,
    MenuItem,
    Typography,
    Link,
    Box,
    Stack,
    FormControl,
    InputLabel,
    Button,
    TextField,
} from "@mui/material";

export default function DefaultRequiredLabel({ labelText = "Label" }) {
    return (
        <Box>
            {labelText}
            <span
                style={{
                    color: "red",
                }}
            >
                *
            </span>
        </Box>
    );
}
