import React from 'react';
import MainButton from './MainButton';

const TextButton = ({...props}) => {
    return (
        <MainButton color='inherit' variant='text' {...props}>
            {props.children}
        </MainButton>
    )
}

export default TextButton;