import React from "react";
import FormContext from "./FormContext";
import { Typography, Box, Grid } from "@mui/material";
import MainButton from "../Buttons/MainButton";
import useIsMobile from "../Mobile";

const FormHandler = ({
    formik,
    plain = false,
    submitText = "Submit",
    submittingText = "Submitting",
    validatingText = "Validating",
    submitBtnId = "",
    ...props
}) => {
    const isMobile = useIsMobile();

    return (
        <FormContext.Provider value={formik}>
            <form onSubmit={formik.handleSubmit}>{props.children}</form>

            {!plain ? (
                <Grid container justifyContent="flex-end">
                    <MainButton
                        id={submitBtnId}
                        loading={formik.isSubmitting || formik.isValidating}
                        onClick={formik.handleSubmit}
                        sx={{
                            mt: 4,
                        }}
                        {...{
                            fullWidth: isMobile,
                        }}
                    >
                        {formik.isValidating
                            ? validatingText
                            : formik.isSubmitting
                            ? submittingText
                            : submitText}
                    </MainButton>
                </Grid>
            ) : null}
        </FormContext.Provider>
    );
};

export default FormHandler;
