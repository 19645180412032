import MaterialReactTable from "material-react-table";
import React, { useState, useEffect, useRef, Fragment } from "react";
import ReactDOM from "react-dom";

export default function IndexTable({
    rows,
    columns,
    hiddenColumns = [],
    isLoading = false,
    defaultDensity = "compact",
    canToggleColumnsVisibility = true,
    showTopToolbar = true,
    max_height = '2000px',
    ...props
}) {
    const hideColumns = {};
    hiddenColumns != []
        ? hiddenColumns.map((item) => {
              hideColumns[item] = false;
          })
        : null;

    return (
        <MaterialReactTable
            columns={columns}
            enableStickyHeader={true}
            enableColumnActions={false}
            enableTopToolbar={showTopToolbar}
            enableHiding={canToggleColumnsVisibility}
            enableColumnFilters={true}
            enableFullScreenToggle={false}
            rowsPerPageOptions={[20, 50, 100]}
            data={rows}
            {...props}
            initialState={{ 
                density: defaultDensity,
                columnVisibility: hideColumns,
                showGlobalFilter: true,
                pagination: {
                    pageSize: 20,
                },
                showGlobalFilter: true,
            }}
            state={{
                showProgressBars: isLoading,
            }}
            muiSearchTextFieldProps={{
                variant: "standard",
                inputProps: {
                    sx: {
                        backgroundColor: "#FFFFFF !important",
                    },
                    id: "global_filter_field",
                },
            }}
            muiTablePaperProps={{
                sx: {
                    boxShadow: "none",
                    // paddingTop: "40px",
                    '.MuiTypography-root' : {
                        maxWidth: '100vw'
                    }
                },
            }}
            muiTableContainerProps={{
                sx: {
                    maxHeight: max_height, // Set max height here
                    overflow: 'auto', // Enable scrolling
                },
            }}
            muiTableHeadCellProps={{
                sx: {
                    borderBottom: "2px solid black",
                    fontSize: "12px",
                    textAlign: "left",
                    verticalAlign: "middle",
                    // "& .Mui-TableHeadCell-Content": {
                    //     display: "flex",
                    //     justifyContent: 'left',
                    //     paddingLeft: 2
                    // },
                    // 'button:has(svg[data-testid="FilterAltIcon"])': {
                    //     display: "none",
                    //     pointerEvents: "none",
                    // },
                    // 'span:has(svg[data-testid="ArrowDownwardIcon"])': {
                    //     display: "none",
                    //     pointerEvents: "none",
                    // },
                    // ".Mui-TableHeadCell-Content-Labels": {
                    //     pointerEvents: "none",
                    // },
                },
            }}
            muiTableBodyCellProps={{
                sx: {
                    fontSize: "12px",
                    whiteSpace: 'normal',
                    wordWrap: "break-word",
                    p: 1,
                    paddingLeft: '16px',
                },
            }}
            muiTablePaginationProps={{
                rowsPerPageOptions: [20, 50, 100],
                sx: {
                    fontSize: "12px",
                    textAlign: "left",
                    "& .MuiTablePagination-selectLabel": {
                        fontSize: "12px",
                        margin: 0,
                        display: "flex",
                        alignItems: "left",
                    },
                    "& .MuiTablePagination-displayedRows": {
                        fontSize: "12px",
                        margin: 0,
                        display: "flex",
                        alignItems: "left",
                    },
                    "& .MuiPopover-paper": {
                        backgroundColor: "red",
                        "& .MuiMenu-list": {
                            "& .MuiTablePagination-menuItem": {
                                fontSize: "12px",
                                color: "red",
                            },
                        },
                    },
                    overflowX: 'hidden',
                    "@media (max-width: 425px)": {
                        "& .MuiTablePagination-toolbar": {
                            textAlign: 'right', 
                            display: 'inline-block',
                        },
                        "& .MuiTablePagination-selectLabel": {
                            display: 'inline', 
                        },
                        "& .MuiTablePagination-actions": {
                            marginLeft: '110px', 
                        },
                        "& .MuiTablePagination-displayedRows": {
                            display: 'inline', 
                            marginBottom: '0',
                        },
                    },
                },
            }}
        />
    );
}
