import React, { useEffect, useState } from "react";
import MaterialReactTable from "material-react-table";
import { ExportToCsv } from "export-to-csv";
import DescriptionIcon from "@mui/icons-material/Description";
import { Box, Button, TextField } from "@mui/material";

const ExportTable = ({
    headers,
    data,
    isloadingStatus,
    labelExportAllData,
    labelExportPerPage,
    handleExportCurrentPage,
    handlExportAll,
    handleExport,
    columnStatus,
    ShownHeaders,
    Names,
    listOfHiddenColumn,
    exportFileName,
    exportTitleName,
    exportPermission,
    rowHeight = 0,
    onGlobalSearchChange = () => {},
    ...props
}) => {
    const [exportVisibleHeader, setExportVisibleHeader] = useState([]);
    useEffect(() => {
        let MuiTableHeadRoot = document.querySelector(".MuiTableHead-root");
        let visibleCols = document.querySelectorAll(
            ".Mui-TableHeadCell-Content-Wrapper"
        );
        MuiTableHeadRoot.addEventListener("change", (e) => {
            visibleCols.forEach((element) => {});
        });
    });
    const csvExporter = new ExportToCsv({
        filename: exportFileName + moment().format("MM-DD-YYYY"),
        title: exportTitleName + moment().format("MM-DD-YYYY"),
        showTitle: true,
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: exportVisibleHeader,
    });

    const handleExportRows = (rows) => {
        let visibleCols = document.querySelectorAll(
            ".Mui-TableHeadCell-Content-Wrapper"
        );
        let exportResults = [];
        visibleCols.forEach((visibleCol) => {
            exportVisibleHeader.push(visibleCol.textContent);
            exportResults = exportVisibleHeader;
        });
        const exportTables = rows.map((row) => {
            let exportVisibleData = [];
            Names.forEach((name) => {
                for (let x = 0; x < exportResults.length; x++) {
                    if (exportResults[x].includes(name.HeaderName)) {
                        exportVisibleData.push(row.original[name.columnName]);
                    }
                }
            });
            return exportVisibleData;
        });
        csvExporter.generateCsv(exportTables);
        setExportVisibleHeader([]);
    };
    return (
        <>
            <MaterialReactTable
                columns={headers}
                data={data}
                state={{
                    isLoading: isloadingStatus,
                }}
                initialState={{
                    density: "compact",
                    pagination: { pageSize: 20 },
                    columnVisibility: listOfHiddenColumn,
                    showColumnFilters: false,
                    showGlobalFilter: true,
                }}
                defaultColumn={{
                    minSize: 50,
                    maxSize: 300,
                }}
                displayColumnDefOptions={{
                    enableHiding: true,
                    enableResizing: true,
                }}
                muiSearchTextFieldProps={{
                    variant: "standard",
                    inputProps: {
                        sx: {
                            backgroundColor: "#FFFFFF !important",
                        },
                    },
                }}
                onGlobalFilterChange={(value) => {
                    onGlobalSearchChange(value);
                    
                }}
                enableGlobalFilter={true}
                enableSorting
                enableStickyHeader
                enableFilterMatchHighlighting
                enableColumnFilterModes={false}
                enableColumnOrdering={false}
                enableColumnResizing={false}
                enableFullScreenToggle={false}
                enableColumnDragging={false}
                muiTablePaperProps={{
                    sx: { boxShadow: "none" },
                }}
                muiTableContainerProps={{
                    sx: {
                        maxHeight: "unset",
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        borderBottom: "2px solid black",
                        fontSize: "12px",
                        textAlign: "left",
                    },
                }}
                muiTableBodyCellProps={{
                    sx: {
                        fontSize: "12px",
                        textAlign: "left",
                        wordWrap: "break-word",
                        paddingY: { rowHeight },
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        paddingY: { rowHeight },
                    },
                }}
                muiTablePaginationProps={{
                    rowsPerPageOptions: [20, 50, 100],
                    sx: {
                        fontSize: "12px",
                        textAlign: "left",
                        "& .MuiTablePagination-selectLabel": {
                            fontSize: "12px",
                            margin: 0,
                            display: "flex",
                            alignItems: "left",
                        },
                        "& .MuiTablePagination-displayedRows": {
                            fontSize: "12px",
                            margin: 0,
                            display: "flex",
                            alignItems: "left",
                        },
                        "& .MuiPopover-paper": {
                            backgroundColor: "red",
                            "& .MuiMenu-list": {
                                "& .MuiTablePagination-menuItem": {
                                    fontSize: "12px",
                                    color: "red",
                                },
                            },
                        },
                    },
                }}
                icons={{
                    SearchIcon: () => null,
                }}
                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                        sx={{
                            display: "flex",
                            gap: "1rem",
                            p: "0.5rem",
                            flexWrap: "wrap",
                        }}
                    >
                        {exportPermission === "true" && (
                            <>
                                <Button
                                    size="small"
                                    sx={{
                                        fontSize: "12px",
                                        padding: "5px 7px",
                                        textTransform: "capitalize",
                                        "& .MuiButtonBase-root:": {
                                            height: "30px",
                                        },
                                    }}
                                    disabled={
                                        table.getPrePaginationRowModel().rows
                                            .length === 0
                                    }
                                    onClick={() => {
                                        handleExportRows(
                                            table.getPrePaginationRowModel()
                                                .rows
                                        );
                                    }}
                                    startIcon={<DescriptionIcon />}
                                    variant="outlined"
                                >
                                    {labelExportAllData}
                                </Button>

                                <Button
                                    size="small"
                                    sx={{
                                        fontSize: "12px",
                                        padding: "5px 7px",
                                        textTransform: "capitalize",
                                        "& .MuiButtonBase-root:": {
                                            height: "30px",
                                        },
                                    }}
                                    disabled={
                                        table.getRowModel().rows.length === 0
                                    }
                                    onClick={() =>
                                        handleExportRows(
                                            table.getRowModel().rows
                                        )
                                    }
                                    startIcon={<DescriptionIcon />}
                                    variant="outlined"
                                >
                                    {labelExportPerPage}
                                </Button>
                            </>
                        )}
                    </Box>
                )}
                {...props}
            />
        </>
    );
};

export default ExportTable;
