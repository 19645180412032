import React, { useState } from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Typography } from '@mui/material'
import Loading from '../Loading';
import Center from '../Center';
import Column from './Column'; 

const tableStyle = {
    border: '1px solid #616161', 
};

const SimpleTable = ({
    columns = [],
    rows = [],
    small,
    noDataLabel = '',
    loading = false,
    noRowsLabel = 'No records to display',
    props
}) => {

    const [rowData, setRowData] = useState(rows);
    const [orderDirection, setOrderDirection] = useState("asc");

    const sortArray = (arr, orderBy, field, isNumeric) => {
        switch (orderBy) {
          case "asc":
          default:
            if(isNumeric == true){
                return arr.sort(function(a,b) { return a[field] -b[field]; })
            }else{
                return arr.sort((a, b) => a[field] > b[field] ? 1 : b[field] > a[field] ? -1 : 0 )
            }            
            
          case "desc":
            if(isNumeric == true){
                return arr.sort(function(a,b) { return b[field] -a[field]; })
            }else{
                return arr.sort((a, b) => a[field] < b[field] ? 1 : b[field] < a[field] ? -1 : 0 )
            }
        }
    };
       
      const handleSortRequest = (field, isNumeric) => {
        setRowData(sortArray(rows, orderDirection, field, isNumeric));
        setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    };

    return (
        <TableContainer>
            <Table size={small ? 'small' : 'medium'} style={tableStyle} {...props}>
                <TableHead>
                    <TableRow>
                        {columns.map((column, index) => (
                            <React.Fragment key={index}>
                                <Column index={index} column={column} orderDirection={orderDirection} handleSortRequest={(field, isNumeric)=>handleSortRequest(field, isNumeric)}/>
                            </React.Fragment>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>

                {
                        loading ?

                        <TableRow>
                            <TableCell colSpan={columns.length}>
                                <Center><Loading /></Center>
                            </TableCell>
                        </TableRow>

                        :

                        rows.length <= 0 && !loading ?
                        <TableRow>
                            <TableCell colSpan={columns.length}>
                            <Center><Typography>{noRowsLabel}</Typography></Center>
                            </TableCell>
                        </TableRow>

                        :

                        rows.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                {columns.map((column, colIndex) => (
                                    <TableCell key={colIndex} align={column.align ?? "left"}>
                                        {column.renderCell ?
                                            column.renderCell(row, rowIndex)
                                        :
                                            _.get(row, column.field) ? _.get(row, column.field) : noDataLabel
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default SimpleTable;
