import Client from "../../../js/components/Client";

const client = Client();

export function base64Images(image_name) {
    return new Promise((resolve, reject) => {
        try {
            switch (image_name) {
                case 'sh_banner':
                    client.get(window.location.origin + '/images/sh/logo-text.png', {
                        responseType: 'blob',
                    }).then((response) => {
                        const blob = response.data;
                        const reader = new FileReader();
                        reader.onload = () => {
                            const base64Data = reader.result;
                            resolve(base64Data);
                        };
                        reader.readAsDataURL(blob);
                    }).catch((error) => {
                        console.error('Error fetching image:', error);
                        reject(error);
                    });
                    break;
                default:
                    reject(new Error('Unknown image name'));
            }
        } catch (error) {
            console.error('Error fetching image:', error);
            reject(error);
        }
    });
}
