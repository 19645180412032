import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

const DefaultAmountField = ({ 
    id, 
    size, 
    label, 
    value, 
    disabled, 
    onChange, 
    fullWidth, 
    fontSize = 14,
    error,
    helperText,
}) => {
    return (
        <TextField
            id={id}
            size={size}
            label={label}
            value={value}
            disabled={disabled}
            onChange={onChange}
            fullWidth={fullWidth}
            error={error}
            helperText={helperText}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        ₱
                    </InputAdornment>
                ),
                style: { fontSize: fontSize },
            }}
            inputProps={{
                type: "number",
                inputMode: "numeric",
                pattern: "[0-9]*",
                min: 1,
                onKeyDown: (e) => {
                    if (["e", "E", "+", "-", "."].includes(e.key)) {
                        e.preventDefault();
                    }
                },
                style: { fontSize: fontSize }, 
            }}
            sx={{ 
                '& .MuiOutlinedInput-input': { 
                    color: 'black',
                    '&:-webkit-autofill': { 
                        WebkitBoxShadow: '0 0 0 100px #f7f9fb inset',
                        WebkitTextFillColor: 'default', 
                    },
                }, 
            }}
        />
    );
};

export default DefaultAmountField;
