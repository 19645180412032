import React, { useState, useEffect } from "react";
import moment from "moment";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import useForm from "./Form";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MainField from "./MainField";

const MainDateTimePicker = ({
    readOnly = false,
    name = "",
    label = "",
    onChange = () => {},
    views = "",
    maxDate = "",
    minDate = "",
    defaultDate = null,
    inputVariant = "filled",
    inputStyle = "",
    ...props
}) => {
    const locale = "en-us";
    const formik = useForm();

    const [currentValue, setCurrentValue] = useState(
        formik && (formik.values[name] === '' || formik.values[name] === undefined)
            ? null
            : moment.isMoment()
            ? formik.values[name]
            : (defaultDate ? moment(defaultDate) : moment())
    );

    const handleChange = (e) => {
        setCurrentValue(e);
        onChange(e);
    };

    useEffect(() => {
        setCurrentValue(currentValue);
        if (formik) {
            if (currentValue == null || currentValue == "") {
                formik.setFieldValue(name, currentValue);
            } else {
                formik.setFieldValue(name, moment(currentValue));
            }
        }
    }, [currentValue]);

    useEffect(() => {
        console.log(formik.values[name]);
    }, [formik.values[name]]);

    return (
        <LocalizationProvider
            dateAdapter={AdapterMoment}
            locale={locale}
        >
            <DateTimePicker
                label={label}
                value={currentValue}
                onChange={(e) => handleChange(e)}
                id={name}
                name={name}
                maxDate={maxDate ? moment(maxDate) : null}
                minDate={minDate ? moment(minDate) : null}
                slotProps={{
                    textField: {
                        error: formik?.touched[name] && Boolean(formik?.errors[name]),
                        helperText: formik?.touched[name] && formik?.errors[name],
                        variant: inputVariant,
                        sx: inputStyle,
                    },
                    popover: {
                        sx: {
                            zIndex: 1000000, 
                        }
                    }
                }}
                
                {...props}
            />
        </LocalizationProvider>
    );
};

export default MainDateTimePicker;
