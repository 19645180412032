import React, { useMemo, useState, useEffect, Fragment } from "react";
import {
    Paper,
    Grid,
    Select,
    MenuItem,
    Typography,
    Link,
    Box,
    FormControl,
    InputLabel,
} from "@mui/material";
import ReactDOM from "react-dom";
import { hashidEncode } from "../../helpers";
import moment from "moment";
import Client from "../Client";
import PromptDialog from "./PromptDialog";
import MaterialReactTable from "material-react-table";
import DefaultButton from "./DefaultButton";

export default function DefaultPatientCasePickerModal({
    modalId = "select_case_modal",
    openModal = false,
    onModalClose = () => {},
    title = "Select Patient Case",
    tableID = "case_table",
    fetchTableDataUrl = "/cis/med/cases/cases_list_server_side",
    tableColumn = useMemo(() => [
        {
            accessorFn: (row) =>
                `${row.repat_date}` ? `${row.repat_date}` : ``,
            header: "Repatriation Date",
            id: "repat_date",
            Cell: ({ renderedCellValue, row }) => {
                var formatted_date = moment(renderedCellValue).isValid()
                    ? moment(renderedCellValue).format("LL")
                    : null;
                return formatted_date;
            },
        },
        {
            accessorKey: "patient_name",
            header: "Patient Name",
            Cell: (cell) => {
                return (
                    <p
                        id={"patient_" + cell.row.index}
                        style={{
                            margin: "auto",
                            color: "#000000",
                        }}
                    >
                        {cell.renderedCellValue}
                    </p>
                );
            },
        },
    ]),
    proceedBtnId = "proceed_btn",
    proceedBtnLabel = "Proceed",
    proceedBtnURL = null,
}) {
    const client = Client();
    const [selectCaseModal, setSelectCaseModal] = useState(openModal);
    const [selectCaseModalBtn, setSelectCaseModalBtn] = useState(true);
    const [caseTableRowSelection, setcaseTableRowSelection] = useState({});
    const [caseTableLoadingBar, setcaseTableLoadingBar] = useState(false);
    const [caseData, setcaseData] = useState([]);
    const [loading, setLoading] = useState(false);
    const handleClose = () => {
        setSelectCaseModal(false);
        onModalClose();
    };
    function fetchcase(case_id) {
        setcaseData([]);
        setcaseTableLoadingBar(true);
        client
            .get(fetchTableDataUrl)
            .then((response) => {
                setcaseTableLoadingBar(false);
                if (response.data.message) {
                    toastr.error("fetching error. Contact IT");
                } else {
                    setcaseData(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
                setcaseTableLoadingBar(false);
                setSelectCaseModal(false);
                toastr.error("fetching error. Contact IT");
            });
    }
    function handleSelectProceedButton() {
        if (proceedBtnURL) {
            setLoading(true);
            const regex = /\{case\}/g;
            const [key, value] = Object.entries(caseTableRowSelection)[0];
            const hashedCaseId = hashidEncode(Number(key));
            const finalUrl = proceedBtnURL.replace(regex, hashedCaseId);
            // console.log("finalUrl:", finalUrl);

            client
                .get("/cis/med/cases/check_picnic", {
                    params: { case_id: key },
                })
                .then((response) => {
                    setLoading(false);
                    if (response.data.check) {
                        toastr.error(
                            "No attending PIC/NIC and Facilitator assigned. Please update the patient's case profile and try again."
                        );
                    } else {
                        window.location.href = finalUrl;
                        handleClose();
                    }
                })
                .catch((error) => {
                    console.error("Error checking case:", error);
                    toastr.error(
                        "An error occurred while checking the case. Please try again."
                    );
                });
        } else {
            console.log("handleProceedBtnURL is null");
        }
    }
    useEffect(() => {
        if (Object.keys(caseTableRowSelection).length > 0) {
            setSelectCaseModalBtn(false);
            if (
                Object.values(caseTableRowSelection).some(
                    (value) => value === false
                )
            ) {
                handleSelectProceedButton();
            }
        } else {
            setSelectCaseModalBtn(true);
        }
    }, [caseTableRowSelection]);

    useEffect(() => {
        fetchcase();
    }, []);

    useEffect(() => {
        if (openModal) {
            setSelectCaseModal(true);
            setSelectCaseModalBtn(false);
        } else {
            setSelectCaseModal(false);
        }
    }, [openModal]);

    useEffect(() => {
        if (openModal) {
            setSelectCaseModal(true);
            setSelectCaseModalBtn(false);
        } else {
            setSelectCaseModal(false);
        }
    }, [openModal]);

    return (
        <PromptDialog
            id={modalId}
            show={selectCaseModal}
            customWidth="540px"
            title=""
            variant="form"
            sx={{ width: "530px" }}
            renderForm={() => {
                return (
                    <>
                        <Typography
                            id="modal-title"
                            variant="h6"
                            component="h2"
                        >
                            {title}
                        </Typography>
                        <MaterialReactTable
                            id={tableID}
                            data={caseData}
                            columns={tableColumn}
                            enableSorting={false}
                            enableColumnFilters={false}
                            paginationDisplayMode={false}
                            enableDensityToggle={false}
                            enableHiding={false}
                            enableFullScreenToggle={false}
                            positionGlobalFilter="left"
                            muiSearchTextFieldProps={{
                                sx: {
                                    width: "160%",
                                    minWidth: "100%",
                                    padding: 0,
                                },
                                size: "small",
                                variant: "outlined",
                            }}
                            initialState={{
                                density: "compact",
                                columnVisibility: {
                                    case_id: false,
                                },
                                pagination: {
                                    pageSize: 10,
                                },
                                showGlobalFilter: true,
                            }}
                            muiTablePaperProps={{
                                sx: {
                                    boxShadow: "none",
                                },
                            }}
                            muiTableContainerProps={{
                                sx: {
                                    maxHeight: "550px",
                                },
                            }}
                            muiTableHeadCellProps={{
                                sx: {
                                    borderBottom: "2px solid black",
                                    fontSize: "12px",
                                    textAlign: "left",
                                    ".Mui-TableHeadCell-Content-Actions": {
                                        display: "none",
                                    },
                                },
                            }}
                            muiTableBodyCellProps={{
                                sx: {
                                    fontSize: "12px",
                                    textAlign: "left",
                                    wordWrap: "break-word",
                                    p: 1,
                                },
                            }}
                            muiTablePaginationProps={{
                                rowsPerPageOptions: [],
                                sx: {
                                    fontSize: "12px",
                                    textAlign: "left",
                                    "& .MuiTablePagination-selectLabel": {
                                        fontSize: "12px",
                                        margin: 0,
                                        display: "flex",
                                        alignItems: "left",
                                    },
                                    "& .MuiTablePagination-displayedRows": {
                                        fontSize: "12px",
                                        margin: 0,
                                        display: "flex",
                                        alignItems: "left",
                                    },
                                    "& .MuiPopover-paper": {
                                        backgroundColor: "red",
                                        "& .MuiMenu-list": {
                                            "& .MuiTablePagination-menuItem": {
                                                fontSize: "12px",
                                                color: "red",
                                            },
                                        },
                                    },
                                },
                            }}
                            enableMultiRowSelection={false} //use radio buttons instead of checkboxes
                            enableRowSelection={false}
                            getRowId={(row) => row.case_id}
                            muiTableBodyRowProps={({ row }) => ({
                                //implement row selection click events manually
                                onClick: () =>
                                    setcaseTableRowSelection((prev) => ({
                                        [row.id]: !prev[row.id],
                                    })),
                                selected: caseTableRowSelection[row.id],
                                sx: {
                                    cursor: "pointer",
                                },
                            })}
                            onRowSelectionChange={setcaseTableRowSelection}
                            state={{
                                caseTableRowSelection,
                                showProgressBars: caseTableLoadingBar,
                            }}
                        />
                        <Grid
                            container
                            spacing={2}
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                            sx={{ marginTop: "2px" }}
                        >
                            <DefaultButton
                                label="Close"
                                variant="outlined"
                                onClick={() => {
                                    handleClose();
                                }}
                            />
                            <DefaultButton
                                id={proceedBtnId}
                                size="small"
                                label={proceedBtnLabel}
                                disabled={selectCaseModalBtn}
                                onClick={handleSelectProceedButton}
                                loading={loading}
                            />
                        </Grid>
                    </>
                );
            }}
            onDialogClose={() => {
                handleClose();
            }}
        />
    );
}
