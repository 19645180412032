import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import ReactDOM from "react-dom";
import {
    Button,
    Stack,
    Slide,
    Typography,
    Grid,
    Divider,
    TextField,
    CircularProgress,
    Box,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    Hidden,
    ListItemButton,
} from "@mui/material";
import Client from "../Client";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Popover from "@mui/material/Popover";
import PaperCard from "../PaperCard";
import Modal from "@mui/material/Modal";
import { MedicalInformationOutlined } from "@mui/icons-material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

const NonLoaWorkflowStatus = ({
    case_id,
    non_loa_id,
    onStateChange,
    onModelWorkflowUpdate,
    onTransitionNameChange,
}) => {
    const [caseID, setCaseID] = useState(case_id);
    const [nonLoaID, setNonLoaID] = useState(non_loa_id);
    const [buttonLabel, setButtonlabel] = useState("...");
    const [buttonColor, setButtonColor] = useState("#ffffff");
    const [isLoading, setIsLoading] = useState(true);
    const [istransitioning, setIstransitioning] = useState(false);
    const [workflowTransitioned, setWorkflowTransitioned] = useState(false);
    const client = Client();
    const [anchorEl, setAnchorEl] = useState(null);
    const [nextTransitions, setNextTransitions] = useState([]);
    const [currentWorkflowStatus, setCurrentWorkflowStatus] = useState([]);
    const [clickedTransitionBtn, setClickedTransitionBtn] = useState();
    const [inputRemarks, setInputRemarks] = useState("");
    const [workflowUpdate, setWorkflowUpdate] = useState(null);

    useEffect(() => {
        onTransitionNameChange(buttonLabel);
    }, [buttonLabel, onTransitionNameChange]);

    const popOverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const popOverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const [openModal, setOpenModal] = useState(false);

    const openModalBox = () => {
        setOpenModal(true);
    };

    const closeModalBox = () => {
        setOpenModal(false);
    };

    const typographyStyle = {
        margin: "none",
        fontSize: "12px",
    };

    const handleInputChange = (event) => {
        setInputRemarks(event.target.value);
    };

    function set_button_label(name, color) {
        if (color == "btn-secondary") {
            setButtonColor("#7c868e");
        }
        if (color == "btn-danger") {
            setButtonColor("#d33f49");
        }
        if (color == "btn-success") {
            setButtonColor("#3ca648");
        }
        if (color == "btn-primary") {
            setButtonColor("#3a79fc");
        }
        setButtonlabel(name);
        setIsLoading(false);
        // console.log("C_stat:", buttonLabel);
        // console.log("btn_color:", buttonColor);
    }

    function formatTransitionBtnColor(type) {
        if (type == "new") {
            return "#7c868e";
        }
        if (type == "void") {
            return "#d33f49";
        }
        if (type == "done") {
            return "#3ca648";
        }
        if (type == "In Progress") {
            return "#3a79fc";
        }
    }

    function transitionNonLoa(transition, remarks) {
        // console.log("Button clicked:", transition.id);
        // console.log("clicked transition name:", transition.name);
        // console.log("currentWorkflowStatus", currentWorkflowStatus);
        // setIstransitioning(true);
        client
            .post("/cis/med/non-loa-list/transition", {
                transition_to: transition,
                transition_from: currentWorkflowStatus,
                remarks: remarks,
                nonloa_id: nonLoaID,
            })
            .then((response) => {
                console.log("transition response:", response.data);
                if (typeof response.data === "string") {
                    popOverClose();
                    getStatus();
                    toastr.error(response.data);
                } else {
                    setIstransitioning(false);
                    setIsLoading(true);
                    setWorkflowTransitioned(true);
                    popOverClose();
                    getStatus();
                    setWorkflowUpdate(response.data);
                }
            });
    }

    function formatTransitions($transitions, inputRemarks) {
        // console.log("transitions:: ", $transitions);
        if ($transitions.length === 0) {
            return (
                <ListItem>
                    <ListItemText primary="No Available Transitions" />
                </ListItem>
            );
        }
        const formattedTransitions = $transitions.map((transition) => {
            const transitionType = transition.type;
            const isRemarks = transition.has_remarks;
            return (
                <ListItemButton
                    key={transition.id}
                    disableGutters
                    disabled={istransitioning}
                    onClick={() => {
                        setClickedTransitionBtn(transition);
                        if (isRemarks) {
                            openModalBox();
                        } else {
                            setIstransitioning(true);
                            transitionNonLoa(transition, inputRemarks);
                        }
                    }}
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        sx={{
                            textTransform: "none",
                            // fontWeight: "bold",
                            fontSize: "12px",
                            // textAlign: "center",
                            marginRight: 2,
                        }}
                    >
                        {transition.hasTransitionName}
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{
                            pl: "5px",
                            pt: "3px",
                            pb: "1px",
                            pr: "5px",
                            minHeight: 0,
                            textTransform: "none",
                            fontWeight: "bold",
                            fontSize: "9px",
                            textAlign: "center",
                            borderRadius: "10px",
                            color: "white",
                            backgroundColor:
                                formatTransitionBtnColor(transitionType),
                        }}
                        size="small"
                        onClick={() => {
                            // setClickedTransitionBtn(transition);
                            // isRemarks
                            //     ? openModalBox()
                            //     : transitionNonLoa(transition, inputRemarks);
                        }}
                    >
                        {transition.workflowStatusName}
                    </Button>
                </ListItemButton>
            );
        });

        return <List>{formattedTransitions}</List>;
    }

    const getStatus = () => {
        // console.log("case id: ", caseID);
        // console.log("nonloa id: ", nonLoaID);
        setWorkflowTransitioned(false);
        client
            .post("/cis/med/non-loa-list/status", {
                nonLoa_id: non_loa_id,
            })
            .then((response) => {
                // console.log("status response:", JSON.stringify(response));
                if (response.data != "") {
                    const statusArray = response.data.status;
                    // console.log("status:", statusArray[0]);
                    setCurrentWorkflowStatus(statusArray[0]);
                    // console.log("statusArray", statusArray);
                    const currentStatus =
                        response.data.status[0].current_status;
                    const btnColor = response.data.status[0].button_color;
                    set_button_label(currentStatus, btnColor);
                    const transitions = response.data.nextTransitions;
                    setNextTransitions(transitions);
                    setInputRemarks("");
                    if (response.data.status[0].is_editable == "false") {
                        document.getElementById("edit_btn").style.display =
                            "none";
                    }
                } else {
                    toastr.error(
                        "Something went wrong in fetching Non-Loa status. Pls contact IT"
                    );
                }
            });
    };

    useEffect(() => {
        onStateChange(workflowTransitioned);
    }, [workflowTransitioned]);
    useEffect(() => {
        onModelWorkflowUpdate(workflowUpdate);
    }, [workflowUpdate]);

    // useEffect(() => {
    //     getStatus();
    //     if (istransitioning == false) {
    //         const interval = setInterval(getStatus, 30000);
    //         return () => clearInterval(interval);
    //     }
    // }, []);
    useEffect(() => {
        // Function to fetch initial status
        const fetchInitialStatus = async () => {
            await getStatus();

            // Set interval to re-fetch status every 30 seconds
            const interval = setInterval(getStatus, 30000);

            // Clear interval when component is unmounted
            return () => clearInterval(interval);
        };

        fetchInitialStatus();
    }, []);

    return (
        <>
            <Modal
                open={openModal}
                onClose={closeModalBox}
                id="remarks_dialog"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        width: 400,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        flexDirection: "column",
                    }}
                >
                    <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ paddingLeft: 2, paddingTop: 2 }}
                    >
                        Add Remarks
                    </Typography>
                    <Box
                        sx={{
                            my: 2,
                            borderBottom: "1px solid #ccc",
                            width: "100%",
                        }}
                    />

                    <Typography
                        gutterBottom
                        sx={{ paddingLeft: 2, paddingTop: 1, paddingRight: 2 }}
                    >
                        <Typography style={typographyStyle}>Remark</Typography>
                        <TextField
                            id="remarks"
                            variant="outlined"
                            size="small"
                            fullWidth
                            sx={{ fontSize: "12px" }}
                            onChange={handleInputChange}
                        />
                    </Typography>
                    <Box
                        sx={{
                            my: 2,
                            borderBottom: "1px solid #ccc",
                            width: "100%",
                        }}
                    />
                    <Typography
                        gutterBottom
                        sx={{
                            paddingLeft: 2,
                            paddingTop: 1,
                            paddingRight: 2,
                            paddingBottom: 1,
                            alignContent: "flex-end",
                        }}
                    >
                        <Button
                            onClick={() => {
                                transitionNonLoa(
                                    clickedTransitionBtn,
                                    inputRemarks
                                );
                                closeModalBox();
                            }}
                            sx={{
                                backgroundColor: "#1976d2",
                                color: "white",
                                pl: 2,
                                pr: 2,
                                fontSize: "12px",
                                float: "right",
                                marginBottom: 2,
                                textTransform: "none",
                                fontWeight: "bold",
                            }}
                        >
                            MOVE
                        </Button>
                    </Typography>
                </Box>
            </Modal>
            <Button
                id="Status_btn"
                aria-describedby={id}
                variant="contained"
                // color={buttonColor}
                sx={{
                    backgroundColor: buttonColor,
                    color: "white",
                    pl: 2,
                    pr: 2,
                    height: "42px",
                    // width: "124px",
                    width: "auto",
                    minWidth: "124px",
                    marginButtom: "5px",
                    fontSize: "12px",
                    textTransform: "none",
                    fontWeight: "bold",
                }}
                // size="medium"
                onClick={popOverOpen}
            >
                {isLoading ? (
                    <CircularProgress size="1rem" color="info" />
                ) : (
                    buttonLabel
                )}

                <ArrowDropDownIcon fontSize="small" />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={popOverClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                sx={{
                    height: "auto",
                    Width: "auto",
                    // minWidth: "165px",
                    // minHeight: "95px",
                    overflow: "hidden",
                    alignContent: "flex-center",
                }}
            >
                <Box
                    sx={{
                        border: "none",
                        height: "auto",
                        width: "auto",
                        paddingLeft: 3,
                        paddingRight: 3,
                    }}
                >
                    <List sx={{ padding: 0 }}>
                        {isLoading ? (
                            <ListItem disableGutters>
                                <CircularProgress size="1rem" color="info" />
                            </ListItem>
                        ) : (
                            formatTransitions(nextTransitions, inputRemarks)
                        )}
                    </List>
                </Box>
            </Popover>
        </>
    );
};

export default NonLoaWorkflowStatus;
