import React, { useState, useEffect } from 'react';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, useTheme, FormHelperText } from '@mui/material';
import useForm from './Form';

const MainRadioGroup = ({
    readOnly = false,
    name = '',
    label = '',
    onChange = () => {},
    options = [],
    row,
    afterChange = () => {},
    selected = null,
    ...props
}) => {

    const formik = useForm();
    const theme = useTheme();

    const [currentValue, setCurrentValue] = useState(formik ? formik.values[name] : '');

    const handleChange = (e) => {
        setCurrentValue(e.target.value);
        if(formik){
          formik.setFieldValue(name, e.target.value)
        }
        afterChange(e.target.value)
    }

    return (
        <FormControl error={formik?.touched[name] && Boolean(formik?.errors[name])}>
          <FormLabel {...props} sx={{'& .MuiFormLabel-asterisk': { color: theme.palette.error.main }}}>{label}</FormLabel>
          <RadioGroup
            row={row ? true:undefined}
            value={currentValue}
            onChange={handleChange}
            name={name}
            defaultValue={selected}
            {...props}
          >
            {options.map((options, i) => (
              <FormControlLabel key={i} label={options?.name} value={options?.value} checked={options?.checked} control={<Radio />}/>
            ))}
          </RadioGroup>
          <FormHelperText>
              {formik?.touched[name] && formik?.errors[name]}
          </FormHelperText>
        </FormControl>
    );
}

export default MainRadioGroup;
