import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

export default function DefaultButton({
    label = 'Click',
    name = '',
    id = '',
    isLoading = false,
    isDisabled = false,
    variant = 'contained',
    ...props
}) {

    return (
        <LoadingButton
            id={id}
            size="small"
            loading={isLoading}
            variant={variant}
            disabled={isDisabled}
            style={{ 
                margin: 4 
            }}
            {...props}
        >
            <span style={{ 
                fontSize: '12px',
                textTransform: 'none'
            }}>{label}</span>
        </LoadingButton>
    );
}
