import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";

export default function IndexQueryTableDateRangePicker({
    id = "",
    name = "",
    label = "Datepicker",
    defaultValue = moment(),
    defaultStartValue = moment().startOf("month"),
    onChange = () => {},
    onDateClear = () => {},
    variant = "datepicker",
    disabled = false,
    clearButton = false,
    localeFormat = "MM-DD-YYYY",
    props,
}) {
    const [dateValue, setDateValue] = useState(defaultValue);
    const [startDate, setStartdate] = useState(defaultStartValue);
    const [endDate, setEnddate] = useState(defaultValue);
    const [inputValue, setInputValue] = useState(null);

    const handleClear = (element) => {
        element.target.value = "";
        setDateValue(
            defaultStartValue.format() + " - " + defaultValue.format()
        );
        onChange(null, null);
        onDateClear();
    };

    let configuration = {
        startDate: dateValue,
        singleDatePicker: true,
        locale: {
            format: localeFormat || "MMMM D, YYYY",
        },
    };

    const handleInputChange = (event) => {
        event.preventDefault();
    };

    switch (variant) {
        case "daterangepicker":
            configuration = {
                startDate: defaultStartValue,
                endDate: dateValue,
                showDropdowns: true,
                autoUpdateInput: false,
                locale: {
                    format: localeFormat || "MMMM D, YYYY",
                    cancelLabel: "Clear",
                },
            };
            break;
        case "datetimerangepicker":
            configuration = {
                startDate: defaultStartValue,
                endDate: dateValue,
                showDropdowns: true,
                autoUpdateInput: false,
                timePicker: true,
                locale: {
                    format: localeFormat || "MMMM D, YYYY - hh:mm A",
                    cancelLabel: "Clear",
                },
            };
            break;
        default:
            break;
    }

    return (
        <div style={{ position: "relative" }}>
            <DateRangePicker
                name={name}
                onCallback={(startDate, endDate) => {
                    setDateValue(
                        startDate.format(localeFormat) +
                            " - " +
                            endDate.format(localeFormat)
                    );
                    setStartdate(startDate);
                    setEnddate(endDate);
                    onChange(startDate, endDate);
                }}
                onCancel={handleClear}
                initialSettings={configuration}
                onApply={(element, picker) => {
                    setStartdate(picker.startDate);
                    setEnddate(picker.endDate);
                    element.target.value =
                        picker.startDate.format(localeFormat) +
                        " - " +
                        picker.endDate.format(localeFormat);
                    onChange(picker.startDate, picker.endDate);
                }}
                onHide={(element) => {
                    setStartdate(defaultStartValue);
                    setEnddate(defaultValue);
                    if (element.target.value == "") {
                        handleClear(element);
                    }
                }}
            >
                {disabled ? (
                    <input
                        id={id}
                        type="text"
                        className="form-control"
                        disabled
                    />
                ) : (
                    <input
                        id={id}
                        type="text"
                        className="form-control"
                        onChange={(element) => {
                            if (element.target.value == "") {
                                handleClear(element);
                            } else {
                                element.preventDefault();
                            }
                        }}
                    />
                )}
            </DateRangePicker>
        </div>
    );
}