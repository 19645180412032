import React, { useState, useEffect } from 'react';
import moment from "moment";
import 'moment-timezone';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import useForm from './Form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MainField from './MainField';

const MainDatePicker = ({
    readOnly = false,
    name = '',
    label = '',
    onChange = () => { },
    views = '',
    maxDate = '',
    minDate = '',
    onDatePickerClear = ()=>{},
    ...props
}) => {

    const locale = "en-us"
    const formik = useForm();

    const [currentValue, setCurrentValue] = useState(
        formik && (formik.values[name] === '' || formik.values[name] === undefined)
            ? null
            : moment.isMoment()
            ? formik.values[name]
            : moment()
    );

    const handleChange = (e) => {
        setCurrentValue(e)
        onChange(e)
    }

    useEffect(() => {
        setCurrentValue(currentValue)
        if (formik) {
            if(currentValue == null || currentValue == ''){
                formik.setFieldValue(name, currentValue);
            }else{
                formik.setFieldValue(name, moment(currentValue));
            }
        }
    }, [currentValue]);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} locale={locale}>
            <DatePicker
                label={label}
                views={['year', 'month', 'day']}
                value={currentValue}
                onChange={(e) => handleChange(e)}
                id={name}
                name={name}
                slotProps={{
                    textField: {
                        fullWidth: true,
                        inputProps: { readOnly: readOnly },
                        error: formik ?
                            formik.validateOnChange ?
                                _.get(formik.touched, name) && Boolean(_.get(formik.errors, name))
                                :
                                Boolean(_.get(formik.errors, name))
                            : false,
                        helperText: formik ?
                            formik.validateOnChange ?
                                _.get(formik.touched, name) && _.get(formik.errors, name)
                                :
                                _.get(formik.errors, name)
                            : null,
                        sx: props.sx,
                        id: props.id + '_button'
                    },
                    popover: {
                        sx: {
                            zIndex: 1000000, 
                        }
                    },
                    inputAdornment: {
                        id: props.id + '_button'
                    },
                    actionBar: {
                        actions: ["clear"]
                    }
                }}
                maxDate={maxDate ? moment(maxDate) : null}
                minDate={minDate ? moment(minDate) : null}
                componentsProps={{
                    actionBar: {
                        actions: ["clear"]
                    }
                }}
                {...props}
            />
        </LocalizationProvider>
    )
}


export default MainDatePicker;