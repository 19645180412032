import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";

const DynamicBreadcrumbs = ({ paths }) => {
    // console.log("paths:", paths);
    const items = JSON.parse(paths);
    return (
        <Breadcrumbs aria-label="breadcrumb">
            {items.map((item, index) => {
                // const isLastItem = index === items.length - 1;
                const breadcrumb =
                    item.url == null ? (
                        <Typography sx={{ fontSize: "12px" }} key={item.title}>
                            {item.title}
                        </Typography>
                    ) : (
                        <Link
                            underline="hover"
                            href={item.url}
                            key={item.title}
                        >
                            <Typography sx={{ fontSize: "12px" }}>
                                {item.title}
                            </Typography>
                        </Link>
                    );

                return breadcrumb;
            })}
        </Breadcrumbs>
    );
};

export default DynamicBreadcrumbs;
