//Route
import Route from 'ziggy';
import { Ziggy } from './ziggy.js';

//run "php artisan ziggy:generate" to generate route name
export function route(name, params, absolute) {        
    return Route(name, params, absolute, Ziggy);
}

//Hashids
import Hashids from 'hashids';

const hashids = new Hashids('asdfasdfhahahaha', 12)
export function hashidEncode(id) {        
    return hashids.encode(id);
}
export function hashidDecode(id) {        
    return hashids.decode(id);
}

export function convertMomentToDatetime(momentObject) {     
    if (moment.isMoment(momentObject)) {
        return momentObject.utc().format('YYYY-MM-DD HH:mm:ss');
    } else {
        return momentObject;
    }
}

export function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}