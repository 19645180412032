import React, { useEffect, useState } from 'react';
import { Menu, Box, Grid, Typography, IconButton, MenuItem } from '@mui/material';
import { Delete, SdCard } from '@mui/icons-material';
import TextButton from '../Buttons/TextButton';
import Center from '../Center';
import MainSelect from '../Form/MainSelect';
import MainField from "../Form/MainField";

const FilterMenu = ({
    columns,
    onFilterModeChange, 
    ...props
}) => {
    const [filters, setFilters] = useState([]);
    const [filterableColumns, setFilterableColumns] = useState([]);

    useEffect(() => {
        columns.map((column)=>(
            column.filterable ? filterableColumns.push(column) : null
        ));
    }, []);

    const operators = [
        "contains",
        "equals",
        "starts with",
        "ends with",
        "is empty",
        "is not empty",
        "is greater than",
        "is less than",
    ];

    const addFilter = () => {
        setFilters([
            ...filters,
            {
                column: null,
                operator: null,
                value: null
            }
        ]);
    }

    const removeFilter = (index) => {
        setFilters(prevFilters => (prevFilters.filter((_, i) => i !== index)));
    }
    
    const handleColumnOnChange = (index, newColumn) => {
        setFilters(prevFilters => [
            ...prevFilters.slice(0, index),
            {
                ...prevFilters[index],
                column: newColumn
            },
            ...prevFilters.slice(index + 1)
        ]);
    }

    const handleOperatorOnChange = (index, newOperator) => {
        setFilters(prevFilters => [
            ...prevFilters.slice(0, index),
            {
                ...prevFilters[index],
                operator: newOperator
            },
            ...prevFilters.slice(index + 1)
        ]);
    }

    const handleValueOnChange = (index, newValue) => {
        setFilters(prevFilters => [
            ...prevFilters.slice(0, index),
            {
                ...prevFilters[index],
                value: newValue
            },
            ...prevFilters.slice(index + 1)
        ]);
    }

    useEffect(() => {
        onFilterModeChange(filters);
    }, [filters]);

    return (
        <Menu
            {...props}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
            {/* to prevent losing focus */}
            <Typography sx={{display: 'none'}} variant="body1">&#8288;</Typography>
            
            <Grid container direction="column" sx={{
                px: 2,
                pt: 1,
                width: '420px'
            }}>
                <Grid item>
                    
                </Grid>

                <Grid item>
                    {filters.map((filter, i) => (
                        <Grid key={i} container sx={{
                            width: '100%',
                            mb: 1
                        }}>
                            <Grid item xs={2} sx={{
                                pt: 1.5
                            }}>
                                <Center>
                                    <IconButton sx={{
                                        mr: 1
                                    }} onClick={() => removeFilter(i)}>
                                        <Delete />
                                    </IconButton>
                                </Center>
                            </Grid>

                            <Grid item xs={3}>
                                <MainSelect
                                    options={filterableColumns}
                                    value={filterableColumns.findIndex(column => column.field == filter.column)}
                                    label="Column"
                                    renderOption={(option) => {
                                        return option.headerName
                                    }}
                                    onChange={(option) => {
                                        handleColumnOnChange(i, option.field);
                                    }}
                                    sx={{
                                        width: '100%'
                                    }}
                                />
                            </Grid>

                            <Grid item xs={3} sx={{
                                pl: 1
                            }}>
                                <MainSelect 
                                    options={operators}
                                    value={operators.findIndex(operator => operator == filter.operator)}
                                    label="Operator"
                                    onChange={(option) => {
                                        handleOperatorOnChange(i, option);
                                    }}
                                    sx={{
                                        width: '100%'
                                    }}
                                />
                            </Grid>

                            <Grid item xs={4} sx={{
                                pl: 1
                            }}>
                                {filter.operator !== "is empty" && filter.operator !== "is not empty" && filter.operator !== null ?
                                    <MainField
                                        label="Value"
                                        value={filter.value ?? ""}
                                        onChange={(value) => {
                                            handleValueOnChange(i, value);
                                        }}
                                    />
                                : null}
                            </Grid>
                        </Grid>
                    ))}

                    {filters.length === 0 ?
                        <Center>
                            <Typography sx={{
                                my: 3
                            }}>
                                No filters selected
                            </Typography>
                        </Center>
                    : null}
                </Grid>

                <Grid item>
                    <TextButton color='inherit' onClick={() => addFilter()}>
                        Add Filter
                    </TextButton>
                </Grid>
            </Grid>
        </Menu>
    )
}

export default FilterMenu;