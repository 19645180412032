import DateRangePicker from "react-bootstrap-daterangepicker";
import React, { useEffect, useState, useRef } from "react";
import moment from "moment";

export default function DefaultDatePicker({
  name = "",
  label = "Datepicker",
  defaultValue = moment(),
  onChange = () => {},
  variant = "datepicker",
  disabled = false,
  minDate = null,
  maxDate = null,
}) {
    const [dateValue, setDateValue] = useState(defaultValue);
    const [minimumDate, setMinimumDate] = useState(minDate);
    const [maximumDate, setMaximumDate] = useState(maxDate);
    const [key, setKey] = useState(0);

    useEffect(() => {
        setMinimumDate(minDate);
        setMaximumDate(maxDate);
    }, [key]);

    useEffect(() => {
        setKey((prevKey) => prevKey + 1);
    }, [minDate, maxDate]);

    var configuration = {
        startDate: dateValue,
        singleDatePicker: true,
        locale: {
        format: "MMMM D, YYYY",
        },
    };

    switch (variant) {
        case "datepicker":
        configuration = {
            startDate: dateValue,
            singleDatePicker: true,
            minDate: minimumDate,
            maxDate: maximumDate,
            locale: {
            format: "MMMM D, YYYY",
            },
        };
        break;
        case "datetimepicker":
        configuration = {
            startDate: dateValue,
            timePicker: true,
            singleDatePicker: true,
            minDate: minimumDate,
            maxDate: maximumDate,
            locale: {
            format: "MMMM D, YYYY - hh:mm A",
            },
        };
        break;
        case "daterangepicker":
        configuration = {
            startDate: dateValue,
            minDate: minimumDate,
            maxDate: maximumDate,
            locale: {
            format: "MMMM D, YYYY",
            },
        };
        break;
        case "datetimerangepicker":
        configuration = {
            startDate: dateValue,
            minDate: minimumDate,
            maxDate: maximumDate,
            timePicker: true,
            locale: {
            format: "MMMM D, YYYY - hh:mm A",
            },
        };
        break;
    }

    return (
        <div key={key}>
        <label style={{ fontWeight: "bold" }}>{label}</label>
        <DateRangePicker
            name={name}
            onCallback={(value) => {
            setDateValue(value);
            onChange(value);
            }}
            initialSettings={configuration}
            onShowCalendar={() => {
            document.querySelectorAll('.daterangepicker').forEach(el => el.style.zIndex = 100000);
            }}
        >
            {disabled ? (
            <input type="text" className="form-control" disabled />
            ) : (
            <input type="text" className="form-control" />
            )}
        </DateRangePicker>
        </div>
    );
}
