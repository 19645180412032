import { Box, Paper, Grid } from "@mui/material";
import React from "react";

const PaperCard = ({
    title = null,
    subTitle = null,
    name = null,
    buttons = null,
    backgroundColor = '#ffffff',
    ...props
}) => {
    const PaperButtons = buttons;

    return (
        <Box {...props}>
            <Paper
                sx={{
                    p: 2.5,
                    minHeight: "50%",
                    width: "100%",
                    mb: 2,
                    backgroundColor: backgroundColor
                }}
            >
                <Grid container columnSpacing={2} rowSpacing={3}>
                    <Grid item xs={6}>
                        {title ? (
                            <div className="d-flex">
                                <h4 className="header-title">{title}</h4>
                                {name ? (
                                    <>
                                        <h4 style={{ paddingLeft: "10px" }}>
                                            -
                                        </h4>
                                        <h4
                                            className="header-title"
                                            style={{ paddingLeft: "10px" }}
                                        >
                                            {name}
                                        </h4>
                                    </>
                                ) : null}
                                {subTitle ? (
                                <p className="sub-header text-muted" style={{ marginTop: 7, marginLeft: 4 }}>
                                    {subTitle}
                                </p>
                            ) : null}
                            </div>
                        ) : null}
                    </Grid>
                    <Grid item xs={6}>
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            // style={{ marginTop: 25 }}
                        >
                            {buttons ? <PaperButtons /> : null}
                        </Box>
                    </Grid>
                </Grid>
                {props.children}
            </Paper>
        </Box>
    );
};

export default PaperCard;
