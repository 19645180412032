import React, { useEffect, useState, useRef } from "react";
import { Radio, RadioGroup } from "@blueprintjs/core";

/**
 *
 * @returns moment instance of date(s)
 */

const DefaultRadioGroup = ({
    name = "",
    label = "",
    defaultSelectedItemIndex = 0,
    options = [],
    onChange = () => {},
    orientation = "horizontal",
    disabled = false,
    id = "",
    props,
}) => {
    // console.log("defaultSelectedItemIndex:", defaultSelectedItemIndex);
    const [selectedItem, setSelectedITem] = useState(
        options[defaultSelectedItemIndex].value
    );
    return (
        <div>
            <label style={{ fontWeight: "bold" }}>{label}</label>
            <RadioGroup
                id={id}
                name={name}
                onChange={(value) => {
                    setSelectedITem(value.target.value);
                    onChange(value.target.value);
                }}
                selectedValue={selectedItem}
                inline={orientation == "horizontal" ? true : false}
                disabled={disabled}
            >
                {options.map((option) => (
                    <Radio
                        label={option.label}
                        value={option.value}
                        id={option.selectorId}
                    />
                ))}
            </RadioGroup>
        </div>
    );
};

export default DefaultRadioGroup;
