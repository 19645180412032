import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Tooltip, Typography, Box } from '@mui/material';
import { FilterAlt } from '@mui/icons-material';
import MainField from '../Form/MainField';
import FilterMenu from './FilterMenu';

const Header = ({
    columns,
    onFilterModeChange,
    onSearchValueChange,
    filter,
    search,
    ...props
}) => {
    const [anchor, setAnchor] = useState(null);
    const [searchableColumns, setSearchableColumns] = useState([]);

    useEffect(()=>{
        columns.map((column)=>(
            column.searchable ? searchableColumns.push(column.headerName) : null
        ));
    },[]);

    

    const open = Boolean(anchor);

    const handleChange = (value) => {
        onSearchValueChange(value);
    }

    const handleFilterClick = (e) => {
        setAnchor(e.currentTarget);
    }

    const handleFilterClose = () => {
        setAnchor(null);
    }

    return (
        <Grid container>
            <Grid item sx={{
                flexGrow: 1
            }}>
                {filter &&
                <React.Fragment>
                    <IconButton onClick={handleFilterClick} >
                        <FilterAlt />
                    </IconButton>

                    <FilterMenu 
                        anchorEl={anchor}
                        open={open}
                        onClose={handleFilterClose}
                        onFilterModeChange={onFilterModeChange}
                        columns={columns}
                    />
                </React.Fragment>}
            </Grid>

            <Grid item
                xs={6}
                sm={4}
                md={3}
                sx={{
                    pr: 1
                }}
            >
                {search &&
                <Tooltip title={
                    <Box>
                        {searchableColumns.length > 0 ?
                        <Typography variant="body2">Searchable Columns</Typography>
                        :<Typography variant="body2">No searchable column</Typography>}
                        {searchableColumns.map((Column, i)=>
                            <Grid key={'grid'+i} item xs={12} >
                                <Typography key={'typography'+i} variant="caption">{Column}</Typography>
                            </Grid>
                        )}
                    </Box>
                    }>
                    <Box>
                        <MainField 
                            placeholder="Search"
                            onChange={handleChange}
                            fullWidth
                        />
                    </Box> 
                </Tooltip>}
            </Grid>
        </Grid>
    )
}

export default Header;