import React, { useEffect, useState, useRef } from "react";
import Select, { components } from "react-select";
import { Box } from "@mui/material";

/**
 * @variations singleselect, multiselect
 * @returns
 */

const NoOptionsMessage = (props) => {
    return (
        <components.NoOptionsMessage {...props}>
            <span className="custom-css-class">No Results Found</span>
        </components.NoOptionsMessage>
    );
};

const DefaultSelect = ({
    name = "",
    id = "",
    label = "",
    defaultSelectedItemIndex = 0,
    defaultSelectedItem,
    options = [],
    showSearchBar = false,
    placeholder = "",
    variant = "singleselect",
    disabled = false,
    props,
    onSelectionChange,
    selectedValue,
    onInputChange,
    isError = false,
    errorMessage = "This is required",
    isLoading,
    widthsize = null,
    menuHeight = null,
    controlStyles,
    inputHeight = "30px",
    optionsFontSize = "12px",
}) => {
    const [selectedItem, setSelectedITem] = useState(
        options[defaultSelectedItemIndex]
    );
    const [isRightClick, setIsRightClick] = useState(false);
    useEffect(() => {
        if (selectedValue) {
            setSelectedITem(selectedValue);
        }
    }, [selectedValue]);

    const handleDocumentClick = (event) => {
        if (event.button === 2) {
            setIsRightClick(true);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleDocumentClick);
        return () => {
            document.removeEventListener("mousedown", handleDocumentClick);
        };
    }, []);

    return (
        <Box>
            {label && <label style={{ fontWeight: "bold" }}>{label}</label>}
            <Select
                name={name}
                id={id}
                options={options}
                isDisabled={disabled}
                onChange={(value) => {
                    setSelectedITem(value);
                    onSelectionChange(value);
                }}
                onMenuClose={() => {
                    if (isRightClick) {
                        setIsRightClick(false);
                        throw new Error("");
                    }
                }}
                defaultValue={defaultSelectedItem ?? selectedItem}
                menuShouldBlockScroll={true}
                isMulti={variant == "singleselect" ? false : true}
                isSearchable={showSearchBar}
                placeholder={"  " + placeholder}
                value={selectedValue}
                menuPortalTarget={document.body}
                components={{ NoOptionsMessage }}
                onInputChange={onInputChange}
                isLoading={isLoading}
                styles={{
                    noOptionsMessage: (base) => ({
                        ...base,
                    }),
                    indicatorsContainer: (provided) => ({
                        ...provided,
                        padding: "0px",
                    }),
                    loadingIndicator: (base) => ({
                        ...base,
                        display: "none",
                    }),
                    dropdownIndicator: (provided) => ({
                        ...provided,
                        padding: "0px",
                    }),
                    clearIndicator: (provided) => ({
                        ...provided,
                        padding: "0px",
                    }),
                    control: (provided) => ({
                        ...provided,
                        backgroundColor: "#F8F9FA",
                        minHeight: inputHeight,
                        ...(isError && { borderColor: "red" }),
                        width: widthsize,
                        ...controlStyles,
                    }),
                    multiValueRemove: (provided) => ({
                        ...provided,
                        padding: "0px",
                    }),
                    multiValueLabel: (provided) => ({
                        ...provided,
                        padding: "0px",
                    }),
                    singleValue: (provided) => ({
                        ...provided,
                        fontWeight: "200",
                        marginLeft: 5,
                        fontSize: optionsFontSize,
                    }),
                    multiValue: (provided) => ({
                        ...provided,
                        fontWeight: "200",
                    }),
                    menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999999,
                    }),
                    menuList: (base) => ({
                        ...base,
                        maxHeight: menuHeight ?? "300px",
                    }),
                    placeholder: (base) => ({
                        ...base,
                        marginLeft: 5,
                    }),
                }}
                {...props}
            />
            {isError && <p style={{ color: "red" }}>{errorMessage}</p>}
        </Box>
    );
};

export default DefaultSelect;
